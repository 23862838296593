<template>
  <div ref="branch" v-loading="loading" class="branch card">
    <div class="section-title">成绩分布</div>
    <div class="operate mt20">
      <div v-if="search.type === 1" class="filter">
        <div class="label">选择学科：</div>
        <el-select
          v-model="search.subjectId"
          style="width: 174px"
          placeholder="请选择"
          @change="subjectIdChange"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="label" style="margin-left: 24px">分数段区间设置：</div>
        <div class="filter-item">
          <div class="filter-item-label">上限</div>
          <el-input
            v-model.number="search.high"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.high = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">下限</div>
          <el-input
            v-model.number="search.low"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.low = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">间隔</div>
          <el-input
            v-model.number="search.interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.interval = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <el-button type="primary" plain @click="submit">确定</el-button>
      </div>
      <div v-else class="filter">
        <div class="label">排名区间设置：</div>
        <div class="filter-item" style="margin-right: 5px">
          <el-input
            v-model.number="search.high"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.high = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">~</div>
          <el-input
            v-model.number="search.low"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.low = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">间隔</div>
          <el-input
            v-model.number="search.interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.interval = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <el-button type="primary" plain @click="submit">确定</el-button>
      </div>
      <div class="raido">
        <el-radio-group
          v-model="search.type"
          size="small"
          @change="raidoChange"
        >
          <el-radio-button :label="1">分数分布</el-radio-button>
          <el-radio-button
            v-if="topData.isSchoolDisplayReportClassRank != 1"
            :label="2"
            >排名分布</el-radio-button
          >
        </el-radio-group>
      </div>
    </div>
    <div v-if="search.type === 1" class="chart-box">
      <bar-chart v-if="chartOptions" :data="chartOptions" :height="500" />
      <no-data v-else></no-data>

      <div v-if="search.type === 1" class="model-desc mt10">
        红色柱子表示班级平均分所在分数段
      </div>
    </div>
    <div v-if="search.type == 2" class="chart-box">
      <heat
        v-if="heatData.data"
        :source="heatData"
        x-aixs-reverse="bottom"
        :item-height="35"
        :tooltip="toolTip"
        :head-height="50"
        :grid-bottom="['80px', '50px']"
        :show-visual-map="true"
        :y-inverse="true"
      />
      <no-data v-else></no-data>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";
import heat from "../../charts/heat.vue";
import {
  getbzrreportscoredistributionScore,
  getbzrreportscoredistributionRank,
} from "@/core/api/academic/headReport";
import {
  UniongetbzrreportscoredistributionScore,
  UniongetbzrreportscoredistributionRank,
} from "@/core/api/academic/unionReport";
import { getstatsubjectoption } from "@/core/api/academic/common";

export default {
  name: "HeadReportBranch",
  components: {
    barChart,
    heat,
  },
  props: {
    classNum: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      toolTip: {
        formatter(params) {
          return params.marker + params.name + ":" + params.data[2] + "人";
        },
      },
      search: {
        type: 1,
        subjectId: 0,
        high: 0,
        low: 0,
        interval: 0,
      },
      heatData: {},
      options: [],
      chartOptions: {},
      heatOptions: {},
      statId: this.$route.query.id,
      unobserve: false,
      loading: false,
      io: {},
      examType: this.$route.query.examType,
    };
  },
  mounted() {
    this.search.high = this.topData.segmentMaximum;
    this.search.low = this.topData.segmentMinimum;
    this.search.interval = this.topData.segmentGap;
    if (this.classNum) {
      this.search.type = 1;
      this.getstatinfoData();
    }
  },
  methods: {
    subjectIdChange(val) {
      let subjectObj = this.options.find((item) => item.id == val);
      if (this.search.type == 1) {
        this.search.high = subjectObj.segmentMaximum;
        this.search.low = subjectObj.segmentMinimum;
        this.search.interval = subjectObj.segmentGap;
        this.getbzrreportscoredistributionData();
      } else if (this.search.type == 2) {
        this.search.high = subjectObj.rankMaximum;
        this.search.low = subjectObj.rankMinimum;
        this.search.interval = subjectObj.rankGap;
        this.getbzrreportscoredistributionRankData();
      }
    },
    raidoChange() {
      if (this.search.type == 1) {
        this.search.high = this.topData.segmentMaximum;
        this.search.low = this.topData.segmentMinimum;
        this.search.interval = this.topData.segmentGap;
        this.getbzrreportscoredistributionData();
      } else {
        this.search.high = this.topData.rankMaximum || null;
        this.search.low = this.topData.rankMinimum || null;
        this.search.interval = this.topData.rankGap || null;
        this.getbzrreportscoredistributionRankData();
      }
    },
    submit() {
      if (this.search.type == 1) {
        this.getbzrreportscoredistributionData();
      } else {
        this.getbzrreportscoredistributionRankData();
      }
    },
    async getstatinfoData(loadingInstance, io) {
      this.loading = true;
      const res = await getstatsubjectoption({
        id: this.statId,
        hasTotal: 1,
        requestPage: 3,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.options = res.data.data;
      this.search.subjectId = res.data.data.length && res.data.data[0].id;
      this.getbzrreportscoredistributionData();
      this.loading = false;
      this.$nextTick(() => {
        if (loadingInstance?.close) {
          this.io = io;
          loadingInstance.close();
          io.unobserve(this.$refs.branch);
        }
      });
    },
    async getbzrreportscoredistributionRankData() {
      if (this.search.high) {
        if (this.search.high > this.search.low) {
          this.$message.error("请注意排名先后");
        } else {
          let res;
          if (this.examType == 1) {
            res = await UniongetbzrreportscoredistributionRank({
              classNum: this.classNum,
              id: this.statId,
              viewSchoolId: this.$route.query.schoolId,
              high: this.search.high,
              interval: this.search.interval,
              low: this.search.low,
            });
          } else {
            res = await getbzrreportscoredistributionRank({
              classNum: this.classNum,
              id: this.statId,
              high: this.search.high,
              interval: this.search.interval,
              low: this.search.low,
            });
          }

          const xAxis = res.data.data.map((item) => item.name);
          const yAxis =
            res.data.data.length &&
            res.data.data[0].segments.map((item) => item.name);
          const data = res.data.data.map((item, index) => {
            return item.segments.map((it, ind) => {
              return [index, ind, it.total];
            });
          });
          const values = res.data.data
            .map((item) => item.segments.map((it) => it.total))
            .flat();
          this.heatData = {
            min: Math.min(...values),
            max: Math.max(...values),
            xAxis: xAxis,
            yAxis: yAxis,
            data: data.flat(),
          };
        }
      } else if (this.search.type == 2 && !this.search.high) {
        this.$alert("请输入排名区间", "", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.chartOptions.series = [];
      }
    },
    async getbzrreportscoredistributionData() {
      if (this.search.interval && this.search.high && this.search.low) {
        let res;
        if (this.examType == 1) {
          res = await UniongetbzrreportscoredistributionScore({
            ...this.search,
            classNum: this.classNum,
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
          });
        } else {
          res = await getbzrreportscoredistributionScore({
            ...this.search,
            classNum: this.classNum,
            id: this.statId,
          });
        }

        this.loading = false;
        this.chartOptions = {
          dataZoom: [
            {
              show: res.data.data.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: res.data.data.length - (res.data.data.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          grid: {
            bottom: res.data.data.length > 25 ? "20%" : "5%",
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          xAxis: [
            {
              type: "category",
              data: res.data.data.map((item) => item.segment),
              axisTick: { show: false },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
                rotate: 25,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisTick: { show: false },
              splitLine: { show: true },
            },
          ],

          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          series: [
            {
              name: "人数",
              type: "bar",

              barMaxWidth: 40,
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
              data: res.data.data.map((item) => {
                if (item.isClassAverage === 1) {
                  return { value: item.total, itemStyle: { color: "#FD8585" } };
                }
                return item.total;
              }),
            },
          ],
        };
      } else {
        this.loading = false;
        this.chartOptions = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.branch {
  .operate {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    .filter {
      display: flex;
      align-items: center;
      color: #5e6166;
      font-size: 14px;
      flex-wrap: wrap;
      .label {
        color: #080a09;
        font-size: 14px;
        margin-right: 24px;
      }
      .filter-item {
        display: flex;
        align-items: center;
        margin-right: 24px;
        font-size: 14px;
        color: #5e6166;
        .el-input {
          margin: 0 8px;
        }
        @media screen and (max-width: 1400px) {
          margin-bottom: 24px;
        }
      }
    }
  }
  .tag {
    height: 60px;
    background-color: #f2f8fe;
    line-height: 60px;
    box-sizing: border-box;
    padding: 0 12px;
    margin: 24px 0;
    color: #737677;
    font-size: 14px;
  }
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
