<template>
  <div ref="heat" :class="['heat']"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Heat",
  props: {
    source: {
      type: Object,
      default: function () {
        return { data: [], xAxis: [], yAxis: [] };
      },
    },
    itemHeight: {
      type: Number,
      default: 30,
    },
    headHeight: {
      type: Number,
      default: 100,
    },
    xAixsReverse: {
      type: String,
      default: "bottom",
    },
    gridBottom: {
      type: Array,
      default: () => ["80px", "50px"],
    },
    gridTop: {
      type: String,
      default: "30px",
    },
    showVisualMap: {
      type: Boolean,
      default: false,
    },
    yInverse: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    source() {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.dispose();
        }
        this.initChart(this.source);
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.source.data) {
        this.initChart(this.source);
      }
    });
  },
  methods: {
    initChart(source) {
      const { xAxis = [], yAxis = [], data, max, min, title } = source;
      if (yAxis.length > 5 && this.size == "small") {
        this.$refs.heat.style.alignItems = "flex-start";
      }

      let dataZoomHeight = xAxis.length > 5 ? 50 : 0;
      this.$refs.heat.style.height =
        yAxis.length * this.itemHeight +
        this.headHeight +
        dataZoomHeight +
        "px";
      let that = this;
      let option = {
        title: {
          text: title,
          show: title !== undefined,
          left: "center",
          textStyle: {
            fontSize: 12,
            color: "#666666",
            textAlign: "center",
            fontWeight: "normal",
          },
        },
        tooltip: {
          position: "top",
          ...that.tooltip,
        },

        grid: {
          bottom: xAxis.length > 5 ? this.gridBottom[0] : this.gridBottom[1],
          top: this.gridTop,
          left: "2%",
          right: "6%",
          containLabel: true,
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 5,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 5),
            xAxisIndex: [0],
            bottom: "10px",
            left: "center",
          },
        ],
        xAxis: {
          type: "category",
          data: xAxis ? [...xAxis] : [],
          position: this.xAixsReverse,
          splitArea: {
            show: true,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#333",
              fontSize: 12,
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 5));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
            formatter(value) {
              return value;
            },
          },
        },

        yAxis: {
          type: "category",
          data: yAxis ? [...yAxis] : [],
          inverse: false,
          splitArea: {
            show: true,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#333",
            },
            formatter(value) {
              return value;
            },
          },
        },
        visualMap: {
          min: min,
          max: max,
          calculable: true,
          orient: "vertical",
          right: "right",
          top: "center",
          show: this.showVisualMap,
          color: ["#539DE6", "#fff"],
        },
        series: [
          {
            name: "",
            type: "heatmap",
            data: [...data],
            label: {
              show: true,
              color: "#333",
              textStyle: {
                fontSize: 9,
              },
              formatter: function (params) {
                if (params.data[2] != 0) {
                  return params.data[2];
                } else {
                  return "";
                }
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.chart = echarts.init(this.$refs.heat, null);
      this.chart.setOption(option, true);
      setTimeout(() => {
        window.onresize = function () {
          this.chart.resize();
        };
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.heat {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
