<template>
  <div class="headReport">
    <div class="topCard">
      <tag />
      <div class="title-box">
        <div v-if="$route.query.ruleId > 0" class="new-exam-tag">新高考</div>

        <div class="title">
          {{ topData.name }}
          <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
            {{ getExamType(topData.type) }}
          </div>
        </div>
      </div>
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <exportBtn
        btn-type="primary"
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 0,
          classNum: activeName,
        }"
        text="导出全部报表"
      >
        <div class="sub-title">
          <div>
            {{ topData.examDate }}<span v-if="level == 2">初</span
            ><span v-if="level == 3">高</span>{{ topData.year }}级
          </div>
        </div>
      </exportBtn>

      <div class="model-title">班主任报告</div>
      <div class="subject-tab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in classList"
            :key="index"
            :label="item.name"
            :name="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div v-loading="loading" class="dashboard">
        <div class="class-scorebox bg-lightgreen">
          <div class="leftpart">
            <p class="ft-green">{{ topData.classAverage }}</p>
            <span>平均分NO.{{ topData.classRank }}</span>
          </div>
          <div class="rightpart">
            <p>
              年级：<span>{{ topData.gradeAverage }}</span>
              <i class="el-icon-check"></i>
            </p>
          </div>
        </div>
        <div
          v-for="(item, index) in topData.topInfoOnlineVOs"
          :key="index"
          class="class-scorebox bg-lightblue"
        >
          <div class="leftpart">
            <p class="ft-green">{{ item.classProportion }}</p>
            <span>{{ item.name }}</span>
          </div>
          <div class="rightpart">
            <p>
              年级：<span>{{ item.gradeProportion }}</span>
              <i class="el-icon-check"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <template v-if="topData.year && activeName">
      <competitive
        id="classCompetitive"
        :key="activeName + 'classCompetitive'"
        :top-data="topData"
        :class-num="activeName"
      />
      <branch
        id="branch"
        :key="activeName + 'branch'"
        :top-data="topData"
        :class-num="activeName"
      />
      <subject
        id="subject"
        :key="activeName + 'subject'"
        :top-data="topData"
        :class-num="activeName"
      />
      <student
        id="student"
        :key="activeName + 'student'"
        :top-data="topData"
        :class-num="activeName"
      />
      <developAnalysis
        id="developAnalysis"
        :key="activeName + 'developAnalysis'"
        :top-data="topData"
        :class-num="activeName"
      />
      <paperAnalysis
        id="paperAnalysis"
        :key="activeName + 'paperAnalysis'"
        :top-data="topData"
        :class-num="activeName"
      />
    </template>
    <page-nav :menu-list="menuList" />
  </div>
</template>

<script>
import { examTypeOptions } from "@/core/util/constdata";
import competitive from "./components/competitive.vue";
import branch from "./components/branch.vue";
import tag from "../components/tag.vue";
import subject from "./components/subject.vue";
import paperAnalysis from "./components/paperAnalysis.vue";
import student from "./components/student.vue";
import developAnalysis from "./components/developAnalysis";
import pageNav from "../components/pageNav.vue";
import { getstatclassoption } from "@/core/api/academic/common";
import { fileDownloadByUrl } from "@/core/util/util";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import {
  getbanzhurenreporttopinfo,
  downloadbanzhurenpdfreport,
  exportHeadReport,
} from "@/core/api/academic/headReport";
import { Uniongetbanzhurenreporttopinfo } from "@/core/api/academic/unionReport";
export default {
  name: "HeadReport",
  components: {
    exportBtn,
    tag,
    competitive,
    branch,
    paperAnalysis,
    subject,
    student,
    developAnalysis,
    pageNav,
  },
  data() {
    return {
      activeName: null,
      requestFunc: exportHeadReport,
      menuList: [
        {
          name: "班级竞争力",
          id: "classCompetitive",
        },
        {
          name: "成绩分布",
          id: "branch",
        },
        {
          name: "优劣势学科",
          id: "subject",
        },
        {
          name: "重点关注学生",
          id: "student",
        },
        {
          name: "发展性分析",
          id: "developAnalysis",
        },
        {
          name: "试卷分析",
          id: "paperAnalysis",
        },
      ],
      statId: this.$route.query.id,
      classList: [],
      topData: {},
      loading: false,
      level: this.$route.query.level,
    };
  },
  mounted() {
    this.getstatclassoptionData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getstatclassoptionData() {
      const res = await getstatclassoption({
        id: this.statId,
        requestPage: 3,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classList = res.data.data;
      this.activeName = res.data.data[0].name;
      this.getbanzhurenreporttopinfoData();
    },
    async getbanzhurenreporttopinfoData() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetbanzhurenreporttopinfo({
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
            classNum: this.activeName,
          });
        } else {
          res = await getbanzhurenreporttopinfo({
            id: this.statId,
            classNum: this.activeName,
          });
        }

        this.topData = res.data.data;
        this.$store.commit("report/SET_TOPDATA", res.data.data);
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    handleClick() {
      this.getbanzhurenreporttopinfoData();
    },
    async downLoad() {
      const res = await downloadbanzhurenpdfreport({
        id: this.statId,
      });
      if (res.data.data.url) {
        fileDownloadByUrl(res.data.data);
      }
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.headReport {
  width: 1200px;
  margin: 0 auto;
  /*& :last-child {*/
  /*margin-right: 0;*/
  /*@media screen and (max-width: 1700px) {*/
  /*margin-right: 24px;*/
  /*}*/
  /*}*/
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
</style>
