<template>
  <div ref="student" v-loading="loading" class="student card">
    <div class="section-title">重点关注学生</div>

    <div class="operate mt20">
      <div class="filter">
        <div v-if="category === 2 || category === 3" class="selects-item">
          {{ category === 3 ? "选择对比考试：" : "选择指标：" }}
          <el-select
            v-model="otherType"
            style="width: 174px; margin-right: 24px"
            placeholder="请选择"
            @change="otherSelectChange"
          >
            <el-option
              v-for="item in otherOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="category === 2" class="selects-item">
          临界分数：
          <el-input
            v-model="interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 117px"
            @blur="interval = $event.target.value"
          ></el-input>
          <el-button
            type="primary"
            style="margin-left: 10px"
            plain
            :disabled="otherOptions.length === 0"
            @click="otherSelectChange"
            >确定</el-button
          >
        </div>
        <div v-if="category === 4 || category === 5" class="selects-item">
          {{ category === 5 ? "校排名后：" : "校排名前："
          }}<el-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 117px; margin-right: 24px"
            @blur="topOrBackward = $event.target.value"
          ></el-input>
          <el-button type="primary" @click="getTopOrBackward">确定</el-button>
        </div>
      </div>
      <el-radio-group v-model="category" size="small" @change="radioChange">
        <el-radio-button
          v-isUnionShow="{ field: 'topInfoOnlineVOs', type: 'length' }"
          :label="2"
          >临界生</el-radio-button
        >
        <el-radio-button
          v-isUnionShow="{ field: 'topInfoOnlineVOs', type: 'length' }"
          :label="3"
          >波动生</el-radio-button
        >
        <el-radio-button
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :label="4"
          >拔尖生</el-radio-button
        >
        <el-radio-button
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :label="5"
          >后进生</el-radio-button
        >
        <el-radio-button :label="1">跟踪生</el-radio-button>
      </el-radio-group>
    </div>

    <div class="chart-box">
      <exportBtn
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 3,
          classNum: classNum,
          criticalInterval: interval,
          topOrBackward: topOrBackward,
          upCompareId: otherType,
          downCompareId: otherType,
        }"
      >
        <div v-if="category === 2" class="title-box">
          <el-radio-group
            v-model="onlineType"
            size="small"
            @change="onlinesChange"
          >
            <el-radio-button :label="1">上线临界</el-radio-button>
            <el-radio-button :label="2">未上线临界</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="category === 3" class="title-box">
          <el-radio-group v-model="wave" size="small" @change="waveChange">
            <el-radio-button :label="1">上升波动</el-radio-button>
            <el-radio-button :label="2">下降波动</el-radio-button>
          </el-radio-group>
        </div>
      </exportBtn>
      <chart
        v-if="category === 3 && tableData.length"
        :data="chartOptions"
        is-ec-stat
        :height="500"
      />
      <div v-if="tableData.length" class="tableList">
        <el-table ref="elTable" :data="tableData" border style="width: 100%">
          <el-table-column prop="label" fixed label="姓名" align="center">
            <template slot-scope="{ row }">
              <span class="name" @click="studentDetail(row)">{{
                row.name
              }}</span>
            </template>
          </el-table-column>
          <div v-if="category === 1">
            <el-table-column prop="label" label="薄弱学科" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.littleSubject && row.littleSubject.length">
                  <span class="subjectShit">{{
                    row.littleSubject.toString()
                  }}</span>
                  稍薄弱
                </span>
                <span
                  v-if="
                    row.littleSubject &&
                    row.seriousSubject &&
                    row.littleSubject.length &&
                    row.seriousSubject.length
                  "
                  >,</span
                >
                <span v-if="row.seriousSubject && row.seriousSubject.length">
                  <span class="subjectShit err">{{
                    row.seriousSubject.toString()
                  }}</span>
                  严重偏科
                </span>
              </template>
            </el-table-column>
            <div
              v-for="(item, index) in subjectData"
              :key="'subjectData' + index"
            >
              <el-table-column :label="item.subjectName" align="center">
                <template slot-scope="{ row }">
                  {{ row.subjects[index].score }}
                </template>
              </el-table-column>
              <!-- 排名 -->
              <el-table-column
                v-if="showCol('rank')"
                :label="item.subjectName + '排名'"
                align="center"
              >
                <template slot-scope="{ row }">
                  {{ row.subjects[index].rank }}
                </template>
              </el-table-column>
            </div>
          </div>
          <div v-if="category === 3">
            <div
              v-for="(item, index) in subjectData"
              :key="index + 'subjectData'"
            >
              <el-table-column
                :key="index + 'subjectId'"
                :label="item.subjectName"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.subjects[index].score }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showCol('rank')"
                :key="'rank' + index"
                width="120"
                :label="
                  item.subjectId === 0 ? '校排名' : `${item.subjectName}排名`
                "
                align="center"
              >
                <template slot-scope="{ row }">
                  <span v-if="item.subjectId === 0">
                    {{ row.subjects[index].rank }}
                  </span>
                  <span v-else>
                    <span v-if="row.subjects[index].rankWave > 0" class="up">
                      <i class="el-icon-top"></i>
                      {{ row.subjects[index].rankWave }}
                    </span>
                    <span
                      v-else-if="row.subjects[index].rankWave < 0"
                      class="down"
                    >
                      <i class="el-icon-bottom"></i>
                      {{ setAbs(row.subjects[index].rankWave) }}
                    </span>
                    <span v-else>
                      {{ setAbs(row.subjects[index].rankWave) }}
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="item.subjectId === 0"
                :key="index + 'ranking'"
                prop="ranking"
                label="增幅"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span v-if="row.subjects[index].rankWave > 0" class="up">
                    <i class="el-icon-top"></i>
                    {{ row.subjects[index].rankWave }}</span
                  >
                  <span
                    v-else-if="row.subjects[index].rankWave < 0"
                    class="down"
                  >
                    <i class="el-icon-bottom"></i>
                    {{ setAbs(row.subjects[index].rankWave) }}
                  </span>
                  <span v-else>
                    {{ setAbs(row.subjects[index].rankWave) }}
                  </span>
                </template>
              </el-table-column>
            </div>
          </div>
          <div v-if="category != 1 && category != 3">
            <el-table-column
              prop="label"
              :label="maxRoleType == 2 ? '薄弱学科' : '潜力学科'"
              :width="subjectData.length ? 120 : 'auto'"
              align="center"
            >
              <template slot-scope="{ row }">
                <span v-if="row.littleSubject && row.littleSubject.length">
                  <span class="subjectShit">{{
                    row.littleSubject.toString()
                  }}</span>
                  稍薄弱
                </span>
                <span
                  v-if="
                    row.littleSubject &&
                    row.seriousSubject &&
                    row.littleSubject.length &&
                    row.seriousSubject.length
                  "
                  >,</span
                >
                <span v-if="row.seriousSubject && row.seriousSubject.length">
                  <span class="subjectShit err">{{
                    row.seriousSubject.toString()
                  }}</span>
                  严重偏科
                </span>
              </template>
            </el-table-column>
            <div
              v-for="(item, index) in subjectData"
              :key="'subjectData' + index"
            >
              <el-table-column
                :label="item.subjectName"
                width="80"
                align="center"
              >
                <template slot-scope="{ row }">
                  {{ row.subjects[index].score }}
                </template>
              </el-table-column>
              <el-table-column
                v-if="showCol('rank')"
                :label="item.subjectName + '排名'"
                width="100"
                align="center"
              >
                <template slot-scope="{ row }">
                  {{ row.subjects[index].rank }}
                </template>
              </el-table-column>
            </div>
          </div>
        </el-table>
      </div>
      <no-data v-else></no-data>
      <div class="model-desc mt20">
        <div>{{ enums[category] }}</div>
        <div v-if="category === 3">
          注：图中横坐标为本次考试，纵坐标为对比考试；参考线上方的学生为上升波动学生，参考线下方的学生为下降波动的学生。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./chart.vue";
import { enums } from "./enum";
import exportBtn from "@/views/academic/components/exportBtn.vue";

import {
  getbzrreportimportstudentTracking,
  getstatinfo,
  getstatcomparelist,
  getbzrreportimportstudentWave,
  getbzrreportimportstudent,
  getbzrreportimportstudentCritical,
  getstatonlineoption,
  exportHeadReport,
} from "@/core/api/academic/headReport";
import {
  UniongetbzrreportimportstudentCritical,
  UniongetbzrreportimportstudentWave,
  Uniongetbzrreportimportstudent,
  UniongetbzrreportimportstudentTracking,
} from "@/core/api/academic/unionReport";
import { mapState } from "vuex";
export default {
  name: "PointStudent",
  components: {
    chart,
    exportBtn,
  },
  props: {
    classNum: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      requestFunc: exportHeadReport,
      category: 2,
      chartOptions: {},
      enums: enums,
      type: 1,
      wave: 1,
      forward: 20,
      statId: this.$route.query.id,
      loading: false,
      tableData: [],
      subjectList: [],
      subjectData: [],
      otherOptions: [],
      waveData: {},
      otherType: "",
      chartData: [],
      topOrBackward: 20,
      interval: 20,
      onlineType: 1,
      onlineData: {},
      unobserve: false,
      maxRoleType: null,
      examType: this.$route.query.examType,
    };
  },
  computed: {
    ...mapState({
      topData: (state) => state.report.topData,
    }),
  },
  watch: {
    topData() {
      this.$nextTick(() => {
        this.category = 2;
        this.radioChange();
      });
    },
  },

  mounted() {
    const { teacherRoleType } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    if (teacherRoleType) {
      const arr = teacherRoleType.split(",");
      this.maxRoleType = Math.max(...arr);
    }
    if (this.examType == 1 && !this.topData.topInfoOnlineVOs.length) {
      if (this.topData.isSchoolDisplayReportClassRank == 1) {
        this.category = 4;
      } else {
        this.category = 1;
      }
      this.radioChange();
    } else if (
      this.examType == 1 &&
      this.topData.isSchoolDisplayReportClassRank != 1
    ) {
      this.category = 1;
      this.$nextTick(() => {
        if (this.classNum || Number(this.classNum)) {
          this.getstatinfoList();
        }
      });
    } else {
      this.category = 4;
      this.radioChange();
      this.$nextTick(() => {
        if (this.classNum || Number(this.classNum)) {
          this.getstatinfoList();
        }
      });
    }
  },

  methods: {
    showCol(field) {
      if (this.examType == 1) {
        if (
          this.topData.isSchoolDisplayReportClassRank == 1 &&
          this.$route.query.isSchoolDisplayReportStudentRank == 1
        ) {
          return true;
        }
        return false;
      } else {
        if (this.subjectData.length) {
          const result = this.subjectData.every((item) => item[field] === null);
          return !result;
        }
        return false;
      }
    },
    studentDetail(row) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          studentId: row.studentId,
          ...this.$route.query,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
    getTopOrBackward() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message("排名应为正整数");
        return;
      } else {
        if (this.category == 4) {
          this.getbzrreportimportstudentData(1);
        } else {
          this.getbzrreportimportstudentData(2);
        }
      }
    },
    radioChange() {
      this.tableData = [];
      this.otherType = "";
      switch (this.category) {
        case 1:
          this.getbzrreportimportstudentTrackingData();
          break;
        case 2:
          this.interval = this.topData.criticalInterval;
          this.getstatonlineoptionData();
          break;
        case 3:
          this.getstatcomparelistData();
          break;
        case 4:
          this.topOrBackward = 20;
          this.getbzrreportimportstudentData(1);
          break;
        case 5:
          this.topOrBackward = 20;
          this.getbzrreportimportstudentData(2);
          break;
      }
    },
    setAbs(val) {
      return Math.abs(val);
    },
    async getstatinfoList() {
      const res = await getstatinfo({ id: this.statId });
      this.subjectList =
        res.data.data.statSubjects.length && res.data.data.statSubjects;
      this.radioChange();
    },
    async getbzrreportimportstudentTrackingData() {
      this.tableData = [];
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetbzrreportimportstudentTracking({
          classNum: this.classNum,
          viewSchoolId: this.$route.query.schoolId,
          id: this.statId,
        });
      } else {
        res = await getbzrreportimportstudentTracking({
          classNum: this.classNum,
          id: this.statId,
        });
      }

      this.loading = false;
      this.subjectData =
        (res.data.data.length && res.data.data[0].subjects) || [];
      this.tableData = res.data.data;
      this.$nextTick(() => {
        if (this.$refs.elTable) this.$refs.elTable.doLayout();
      });
    },
    async getbzrreportimportstudentWaveData() {
      this.tableData = [];
      this.loading = true;
      try {
        let res;
        if (this.examType == 1) {
          res = await UniongetbzrreportimportstudentWave({
            classNum: this.classNum,
            compareStatId: this.otherType,
            id: this.statId,
          });
        } else {
          res = await getbzrreportimportstudentWave({
            classNum: this.classNum,
            compareStatId: this.otherType,
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
          });
        }

        this.loading = false;
        this.waveData = res.data.data;
        this.setWaveChartOptions([
          ...res.data.data.downStudents,
          ...res.data.data.upStudents,
        ]);
        if (!this.tableData.length) {
          if (res.data.data.upStudents.length) {
            this.wave = 1;
            this.tableData = res.data.data.upStudents;
            this.subjectData = res.data.data.upStudents[0].subjects;
          } else if (res.data.data.downStudents.length) {
            this.tableData = res.data.data.downStudents;
            this.subjectData = res.data.data.downStudents[0].subjects;
            this.wave = 2;
          }
        } else {
          if (this.onlineType == 1) {
            this.tableData = res.data.data.upStudents;
            this.subjectData = res.data.data.upStudents[0].subjects;
          } else if (this.onlineType == 2) {
            this.tableData = this.onlineData.downStudents;
            this.tableData = res.data.data.downStudents;
            this.subjectData = res.data.data.downStudents[0].subjects;
          }
        }

        this.$nextTick(() => {
          if (this.$refs.elTable) this.$refs.elTable.doLayout();
        });
      } catch {
        this.loading = false;
      }
    },
    setWaveChartOptions(data) {
      const arr = data.map((item) => {
        const totalItem = item.subjects.find((it) => it.subjectId === 0);
        return [totalItem.rank, totalItem.rank + totalItem.rankWave, item.name];
      });
      const compareRank = arr.map((item) => item[1]);
      const rank = arr.map((item) => item[0]);
      const maxCompareRank = Math.max(...compareRank);
      const maxRank = Math.max(...rank);
      this.chartOptions = {
        tooltip: {
          axisPointer: {
            type: "shadow",
          },
          formatter: function (param) {
            return `${param.data[2]}:${param.data[0]},${param.data[1]}`;
          },
        },
        toolbox: {
          feature: {
            dataZoom: {},
          },
        },
        dataset: [
          {
            source: arr,
          },
          {
            transform: {
              type: "ecStat:regression",
              config: { method: "polynomial" },
            },
          },
        ],
        xAxis: {
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          axisLine: {
            show: true,
            lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
          },
          splitLine: { show: false },
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
          },
          axisTick: {
            show: true,
            lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
          },
          splitLine: { show: false },
        },
        series: [
          {
            symbolSize: 10,
            name: "",
            type: "scatter",
            datasetIndex: 0,
            markLine: {
              lineStyle: {
                type: "solid",
                color: "#FF9776",
              },
              tooltip: {
                show: false,
              },
              data: [
                [
                  {
                    coord: [0, 0],
                    symbol: "none",
                  },
                  {
                    coord: [maxRank, maxCompareRank],
                    symbol: "none",
                  },
                ],
              ],
            },
            label: {
              show: true,
              position: "top",
              color: "inherit",
              fontSize: 9,
              formatter: function (params) {
                return `${params.data[2]}`;
              },
            },
          },
        ],
      };
    },

    otherSelectChange() {
      this.tableData = [];
      if (this.category === 3) {
        this.getbzrreportimportstudentWaveData();
      } else if (this.category === 2) {
        this.getbzrreportimportstudentCriticalData();
      }
    },
    waveChange() {
      if (this.waveData.upStudents && this.waveData.downStudents) {
        if (this.wave === 1) {
          this.tableData = this.waveData.upStudents;
          this.subjectData =
            this.waveData.upStudents.length &&
            this.waveData.upStudents[0].subjects;
          this.$nextTick(() => {
            if (this.$refs.elTable) this.$refs.elTable.doLayout();
          });
        } else if (this.wave === 2) {
          this.tableData = this.waveData.downStudents;
          this.subjectData =
            this.waveData.downStudents.length &&
            this.waveData.downStudents[0].subjects;
          this.$nextTick(() => {
            if (this.$refs.elTable) this.$refs.elTable.doLayout();
          });
        }
      }
    },
    async getstatcomparelistData() {
      const res = await getstatcomparelist({ statId: this.statId });
      this.otherOptions = res.data.data;
      this.otherType = (res.data.data.length && res.data.data[0].id) || "";
      if (res.data.data.length) {
        this.getbzrreportimportstudentWaveData();
      } else {
        this.loading = false;
        this.chartOptions = null;
      }
    },
    async getbzrreportimportstudentCriticalData() {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = UniongetbzrreportimportstudentCritical({
          classNum: this.classNum,
          viewSchoolId: this.$route.query.schoolId,
          interval: this.interval,
          onlineId: this.otherType,
          id: this.statId,
        });
      }
      res = await getbzrreportimportstudentCritical({
        classNum: this.classNum,
        interval: this.interval,
        onlineId: this.otherType,
        id: this.statId,
      });
      this.loading = false;
      const { upStudents, downStudents } = res.data.data;
      this.onlineData = res.data.data;
      if (upStudents.length) {
        this.tableData = res.data.data.upStudents;
        this.subjectData = res.data.data.upStudents[0].subjects;
        this.onlineType = 1;
      } else if (downStudents.length) {
        this.tableData = res.data.data.downStudents;
        this.subjectData = res.data.data.downStudents[0].subjects;
        this.onlineType = 2;
      }
    },
    onlinesChange() {
      if (this.onlineData.upStudents && this.onlineData.downStudents) {
        switch (this.onlineType) {
          case 1:
            if (this.onlineData.upStudents.length) {
              this.subjectData = this.onlineData.upStudents[0].subjects;
              this.tableData = this.onlineData.upStudents;
              this.$nextTick(() => {
                if (this.$refs.elTable) this.$refs.elTable.doLayout();
              });
            } else {
              this.subjectData = [];
              this.tableData = [];
            }
            break;
          case 2:
            if (this.onlineData.downStudents.length) {
              this.subjectData = this.onlineData.downStudents[0].subjects;
              this.tableData = this.onlineData.downStudents;
              this.$nextTick(() => {
                if (this.$refs.elTable) this.$refs.elTable.doLayout();
              });
            } else {
              this.subjectData = [];
              this.tableData = [];
            }

            break;
        }
      }
    },
    async getstatonlineoptionData() {
      const res = await getstatonlineoption({
        id: this.statId,
      });
      this.otherOptions = res.data.data;
      if (res.data.data.length) {
        this.otherType = res.data.data[0].id;
        this.getbzrreportimportstudentCriticalData();
      }
    },
    async getbzrreportimportstudentData(type) {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await Uniongetbzrreportimportstudent({
          classNum: this.classNum,
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
          topOrBackward: this.topOrBackward,
          type: type,
        });
      } else {
        res = await getbzrreportimportstudent({
          classNum: this.classNum,
          id: this.statId,
          topOrBackward: this.topOrBackward,
          type: type,
        });
      }

      this.loading = false;
      this.tableData = res.data.data;
      this.subjectData = res.data.data.length && res.data.data[0].subjects;
      this.$nextTick(() => {
        if (this.$refs.elTable) this.$refs.elTable.doLayout();
      });
    },
    getSubjectName(subjectId) {
      if (!Number(subjectId)) {
        return "总分";
      } else {
        let item = this.subjectList.find((item) => item.subjectId == subjectId);
        return item ? item.name : "-";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.student {
  .err {
    color: red;
  }

  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .title {
      font-weight: bold;
      color: #101011;
      font-size: 14px;
    }
  }
}

.tag {
  background-color: #f2f8fe;
  text-indent: 2em;
  box-sizing: border-box;
  padding: 24px;
  margin: 24px 0;
  color: #737677;
  line-height: 1.5;
  font-size: 14px;
}

.name {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
  color: #2474ed;
}

.up {
  color: #67c23a;
}

.down {
  color: #fc7070;
}

.seriousSubject {
  color: #fc7070;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
