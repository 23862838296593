<template>
  <div class="headerTable">
    <table v-if="data.length" border="1">
      <tr>
        <th rowspan="2" class="td1">学生</th>
        <th v-if="showColumn('schoolRank')" rowspan="2" class="td2">校排名</th>
        <th :colspan="objectiveQuestionsLength">客观题</th>
        <th :colspan="subjectiveQuestionsLength">主观题</th>
      </tr>
      <tr>
        <th
          v-for="(item, index) in head.objectiveQuestions"
          :key="'objectiveQuestionsQuestionOrder' + index"
        >
          {{ item.questionOrder }}
        </th>
        <th
          v-for="(item, index) in head.subjectiveQuestions"
          :key="'subjectiveQuestionsQuestionOrder' + index"
        >
          {{ item.questionOrder }}
        </th>
      </tr>
      <tr>
        <th :colspan="showColumn('schoolRank') ? 2 : 1" class="td1">
          正确答案/满分分值
        </th>
        <th
          v-for="(item, index) in head.objectiveQuestions"
          :key="'objectiveQuestionsError' + index"
        >
          <span v-if="item.questionOrder === '客观题'">{{ item.score }}</span>
          <span v-else>{{ item.rightAnswer }}</span>
        </th>
        <th
          v-for="(item, index) in head.subjectiveQuestions"
          :key="'subjectiveQuestionsError' + index"
        >
          {{ item.score }}
        </th>
      </tr>
      <tr v-for="(item, index) in body" :key="'body' + index">
        <td class="td1">
          <el-button type="text" @click="toStudentDetail(item)">{{
            item.name
          }}</el-button>
        </td>
        <td v-if="showColumn('schoolRank')" class="td2">
          {{ item.schoolRank }}
        </td>
        <td
          v-for="(it, ind) in head.objectiveQuestions"
          :key="'bodyObjectiveQuestions' + ind"
        >
          <span>{{ getQuestionOrder(item, it) }}</span>
        </td>
        <td
          v-for="(it, ind) in head.subjectiveQuestions"
          :key="'bodySubjectiveQuestions' + ind"
        >
          <span>{{ getsubjectiveQuestionOrder(item, it) }}</span>
        </td>
      </tr>
    </table>
    <no-data v-else />
  </div>
</template>

<script>
export default {
  name: "HeaderTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      head: {},
      body: {},
      objectiveQuestionsLength: 0,
      subjectiveQuestionsLength: 0,
      statId: this.$route.query.id,
      examType: this.$route.query.examType,
    };
  },
  watch: {
    data(val) {
      if (val.length) {
        this.head = {
          objectiveQuestions: val[0].questions.filter(
            (item) => item.isObjective == 1
          ),
          subjectiveQuestions: val[0].questions.filter(
            (item) => item.isObjective == 0
          ),
        };
        this.body = [...val];
        this.subjectiveQuestionsLength = this.head.subjectiveQuestions.length;
        this.objectiveQuestionsLength = this.head.objectiveQuestions.length;
      }
    },
  },
  created() {
    if (this.$route.query.code) {
      this.statId = this.$route.query.id;
    } else {
      this.statId = this.$route.query.id;
    }
  },
  methods: {
    showColumn(field) {
      if (this.examType == 1) {
        if (this.topData.isSchoolDisplayReportClassRank == 1) {
          return true;
        }
        return false;
      } else {
        if (this.data.length) {
          const result = this.data.every((item) => item[field] === null);
          return !result;
        }
        return true;
      }
    },
    toStudentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          studentId: item.studentId,
          ...this.$route.query,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
    getQuestionOrder(item, it) {
      const data = item.questions.find(
        (i) => i.questionOrder === it.questionOrder
      );

      if (it.questionOrder === "客观题") {
        return data.lostScore;
      }
      return data.wrongAnswer;
    },
    getsubjectiveQuestionOrder(item, it) {
      const data = item.questions.find(
        (i) => i.questionOrder === it.questionOrder
      );
      return data.lostScore;
    },
  },
};
</script>
<style lang="scss" scoped>
.headerTable {
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 20px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: #999;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    border-radius: 10px;
    background: #ededed;
  }
  table {
    width: 100%;
    border-color: #ebeef5;

    tr {
      th {
        min-width: 80px;
        height: 37px;
        background-color: #f2f5f8;
        text-align: center;
        border-color: #ebeef5;
        font-size: 14px;
        color: #101011;
      }
      & :last-child {
        outline: none;
      }
      td {
        min-width: 80px;
        height: 42px;
        background-color: #fff;
        text-align: center;
        border-color: #ebeef5;
        font-size: 14px;
        color: #909399;
      }
    }
  }
}
.td1 {
  position: sticky;
  z-index: 1;
  text-align: center;
  left: 0;
  padding: 0 12px;
  outline: 1px solid #ebeef5;
}
.td2 {
  position: sticky;
  z-index: 1;

  text-align: center;
  outline: 1px solid #ebeef5;

  left: 80px;
}
table thead tr {
  border: none;
  outline-color: #fff;
  text-align: center;
  outline-style: solid;
  outline-width: 1px;
  border-color: #ebeef5;
}
</style>
