<template>
  <div v-loading="loading" class="competitive card">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 1,
        classNum: classNum,
      }"
    >
      <div class="section-title">班级竞争力</div>
    </exportBtn>
    <div class="mt20 radio">
      <el-radio-group v-model="type" size="small" @change="typeChange">
        <el-radio-button
          v-isUnionShow="{ field: 'topInfoOnlineVOs', type: 'length' }"
          :label="1"
          >上线情况</el-radio-button
        >
        <el-radio-button :label="2">比率</el-radio-button>
        <el-radio-button :label="3">前N名</el-radio-button>
        <el-radio-button :label="4">其他指标</el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="type === 1" class="charts">
      <div class="charts-item">
        <div class="filter">
          <div class="title">上线情况分析</div>
          <el-radio-group
            v-if="examType != 1"
            v-model="onlineAnalyseDataType"
            size="small"
            @change="olineAnalyChange"
          >
            <el-radio-button :label="1">占比</el-radio-button>
            <el-radio-button :label="2">人数</el-radio-button>
          </el-radio-group>
          <el-select
            v-else
            v-model="online"
            style="width: 120px"
            @change="olineAnalyChange"
          >
            <el-option
              v-for="(item, index) in onlineData"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>
        <bar-charts :data="onlineAnalyseDataOptions" :height="430" />
      </div>
      <div class="dashed-line mlr10"></div>
      <div class="charts-item">
        <div class="filter">
          <div class="title">临界情况分析</div>
        </div>
        <bar-charts :data="criticalAnalyseDataOptions" :height="430" />
      </div>
      <div class="dashed-line mlr10"></div>
      <div class="charts-item dott-line">
        <div v-if="onlineData.length" class="space">
          <div class="top edit-scroll-style">
            <div class="title">
              {{ onlineData[subIndex].name }}有效分({{
                onlineData[subIndex].ep
              }})
            </div>
            <div class="title-box">
              <div>
                上线临界生（{{ onlineData[subIndex].upStudents.length }}人）
              </div>
              <div>
                （{{ onlineData[subIndex].ep }}分~{{
                  onlineData[subIndex].ep +
                  onlineData[subIndex].criticalInterval
                }}分）
              </div>
            </div>
            <div
              v-if="onlineData[subIndex].upStudents.length"
              class="nameList mb36"
            >
              <div
                v-for="item in onlineData[subIndex].upStudents"
                :key="item.studentId"
                class="nameList-item"
                @click="sutdentDetail(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <no-data v-else size="small"></no-data>
            <div class="title-box">
              <div>
                未上线临界生（{{ onlineData[subIndex].downStudents.length }}人）
              </div>
              <div>
                （{{
                  onlineData[subIndex].ep -
                  onlineData[subIndex].criticalInterval
                }}分~{{ onlineData[subIndex].ep }}分）
              </div>
            </div>
            <div
              v-if="onlineData[subIndex].downStudents.length"
              class="nameList"
            >
              <div
                v-for="item in onlineData[subIndex].downStudents"
                :key="item.studentId"
                class="nameList-item"
                @click="sutdentDetail(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <no-data v-else size="small"></no-data>
          </div>
          <div v-if="onlineData.length" class="bottom">
            <div>
              <span v-if="subIndex > 0" @click="prev">
                <i class="el-icon-arrow-left"></i
                >{{ onlineData[subIndex - 1].name }}
              </span>
            </div>
            <div>
              <span v-if="subIndex < onlineData.length - 1" @click="next"
                >{{ onlineData[subIndex + 1].name
                }}<i class="el-icon-arrow-right"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 2" class="gridCharts">
      <div class="gridCharts-item">
        <el-select
          v-if="examType == 1"
          v-model="online"
          style="width: 150px; margin: 0 0 18px 36px"
          @change="setRatiosChart"
        >
          <el-option
            v-for="(item, index) in studentLevelNameList"
            :key="index"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
        <bar-charts :data="studentLevelOptions" />
      </div>
      <div
        v-if="studentLevelNameList.length"
        class="gridCharts-item dott-line"
        style="margin-right: 20px"
      >
        <div class="space">
          <div class="top edit-scroll-style">
            <div class="title-box">
              <div>
                {{ studentLevelNameList[subIndex].name }}的学生({{
                  studentLevelNameList[subIndex].students.length
                }})人
              </div>
              <div>
                （{{ studentLevelNameList[subIndex].leftValue }}分-{{
                  studentLevelNameList[subIndex].rightValue
                }}分）
              </div>
            </div>
            <div
              v-if="studentLevelNameList[subIndex].students.length"
              class="nameList mb36"
            >
              <div
                v-for="item in studentLevelNameList[subIndex].students"
                :key="item.studentId"
                class="nameList-item"
                @click="sutdentDetail(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <no-data v-else />
          </div>
          <div v-if="studentLevelNameList.length" class="bottom">
            <div>
              <span v-if="subIndex > 0" @click="prevLevel">
                <i class="el-icon-arrow-left"></i
                >{{ studentLevelNameList[subIndex - 1].name }}
              </span>
            </div>
            <div>
              <span
                v-if="subIndex < studentLevelNameList.length - 1"
                @click="nextLevel"
                >{{ studentLevelNameList[subIndex + 1].name
                }}<i class="el-icon-arrow-right"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 3" class="gridCharts">
      <div class="gridCharts-item">
        <div class="title">
          前N名情况分析 <span>注：本报告中的前N名均为我校范围的前N名</span>
        </div>
        <el-select
          v-if="examType == 1"
          v-model="online"
          style="width: 150px; margin: 0 0 18px 36px"
          @change="setTopsetctionChart"
        >
          <el-option
            v-for="(item, index) in topList"
            :key="index"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
        <bar-charts :data="topOptions" />
      </div>
      <div class="gridCharts-item dott-line" style="margin-right: 20px">
        <div v-if="topList.length" class="space">
          <div class="top edit-scroll-style">
            <div class="title-box">
              <div>
                {{ topList[subIndex].name }}学生({{
                  topList[subIndex].students.length
                }})人
              </div>
              <div>
                （{{ topList[subIndex].leftValue }}分~{{
                  topList[subIndex].rightValue
                }}分）
              </div>
            </div>
            <div v-if="topList[subIndex].students.length" class="nameList mb36">
              <div
                v-for="item in topList[subIndex].students"
                :key="item.studentId"
                class="nameList-item"
                @click="sutdentDetail(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <no-data v-else />
          </div>
          <div v-if="topList.length" class="bottom">
            <div>
              <span v-if="subIndex > 0" @click="prevTop">
                <i class="el-icon-arrow-left"></i
                >{{ topList[subIndex - 1].name }}
              </span>
            </div>
            <div>
              <span v-if="subIndex < topList.length - 1" @click="nextTop"
                >{{ topList[subIndex + 1].name
                }}<i class="el-icon-arrow-right"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 4" class="tableList">
      <el-table
        ref="elTable"
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="cellStyle"
      >
        <el-table-column v-if="examType != 1" prop="type" align="center">
          <template slot-scope="{ row }">
            {{ getTypeLabel(row.type) }}
          </template>
        </el-table-column>
        <el-table-column v-else prop="name" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="highestScore" label="最高分" align="center">
        </el-table-column>
        <el-table-column prop="lowestScore" label="最低分" align="center">
        </el-table-column>
        <el-table-column prop="standardDeviation" label="标准差" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import barCharts from "./chart.vue";
import {
  getbzrreportclasscompetitiveOnline,
  getbzrreportclasscompetitiveStudentlevel,
  getbzrreportclasscompetitiveTop,
  getbzrreportclasscompetitiveOther,
  exportHeadReport,
} from "@/core/api/academic/headReport";
import {
  UniongetbzrreportclasscompetitiveOnline,
  UniongetbzrreportclasscompetitiveStudentlevel,
  UniongetbzrreportclasscompetitiveTop,
  UniongetbzrreportclasscompetitiveOther,
} from "@/core/api/academic/unionReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";

export default {
  name: "HeadReportCompetitive",
  components: {
    barCharts,
    exportBtn,
  },
  props: {
    classNum: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requestFunc: exportHeadReport,
      examType: this.$route.query.examType,
      type: 1,
      tableData: [],
      statId: this.$route.query.id,
      loading: false,
      status: 1,
      online: null,
      onlineData: [],
      onlineAnalyseDataType: 1,
      onlineAnalyseDataOptions: {},
      criticalAnalyseDataOptions: {},
      subIndex: 0,
      studentLevelOptions: {},
      studentLevelNameList: [],
      topList: [],
      topOptions: {},
      cancleIo: false,
    };
  },
  mounted() {
    if (this.examType == 1 && !this.topData.topInfoOnlineVOs.length) {
      this.type = 2;
    }
    this.$nextTick(() => {
      if (this.classNum || Number(this.classNum)) {
        this.typeChange();
      }
    });
  },
  methods: {
    cellStyle({ row, rowIndex, columnIndex }) {
      if (rowIndex == 2 && columnIndex == 1) {
        if (row.highestScore > 0) {
          return "color:#6EDBA7;font-weight:bold";
        } else if (row.highestScore < 0) {
          return "color:#FD8585;font-weight:bold";
        } else {
          return "font-weight:bold";
        }
      } else if (rowIndex == 2 && columnIndex == 2) {
        if (row.lowestScore > 0) {
          return "color:#6EDBA7;font-weight:bold";
        } else if (row.lowestScore < 0) {
          return "color:#FD8585;font-weight:bold";
        } else {
          return "font-weight:bold";
        }
      } else if (rowIndex == 2 && columnIndex == 3) {
        if (row.standardDeviation > 0) {
          return "color:#6EDBA7;font-weight:bold";
        } else if (row.standardDeviation < 0) {
          return "color:#FD8585;font-weight:bold";
        } else {
          return "font-weight:bold";
        }
      }
    },
    sutdentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentId: item.studentId,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
    async getbzrreportclasscompetitiveOnlineData() {
      // 上限情况
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await UniongetbzrreportclasscompetitiveOnline({
            id: this.statId,
            viewSchoolId: this.$route.query.schoolId,
            classNum: this.classNum,
          });
        } else {
          res = await getbzrreportclasscompetitiveOnline({
            id: this.statId,
            classNum: this.classNum,
          });
        }

        this.loading = false;
        if (res.data.data.length) {
          this.online = res.data.data[0].name;
        }

        this.onlineData = res.data.data;
        if (res.data.data.length) {
          this.optionValue = res.data.data[0].name;
        }
        this.criticalAnalyseDataOptions = {
          legend: { itemGap: 30 },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          xAxis: [
            {
              type: "category",
              data: this.onlineData.map((item) => item.name),
              axisTick: { show: false },
              splitLine: { show: false },
              axisLine: { show: false },
            },
          ],
          color: ["#7AB5EF", "#FD8585", "#6EDBA7", "#FFD766"],
          yAxis: [
            {
              type: "value",
              axisTick: { show: false },
              splitLine: { show: true },
              axisLine: { show: false },
              boundaryGap: [0.2, 0.2],
            },
          ],
          series: [
            {
              name: "上线临界",
              type: "bar",
              barMaxWidth: 40,
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
              data: this.onlineData.map((item) => item.upTotal),
            },
            {
              name: "未上线临界",
              type: "bar",
              barMaxWidth: 40,

              data: this.onlineData.map((item) => ({
                value: item.downTotal ? 0 - item.downTotal : 0,
                label: {
                  position: 0 - item.downTotal >= 0 ? "top" : "bottom",
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                  formatter: ({ value }) => Math.abs(value),
                },
              })),
            },
          ],
        };
        this.olineAnalyChange();
      } catch {
        this.loading = false;
      }
    },
    async getbzrreportclasscompetitiveStudentlevelData() {
      // 学生等级
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await UniongetbzrreportclasscompetitiveStudentlevel({
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
            classNum: this.classNum,
          });
        } else {
          res = await getbzrreportclasscompetitiveStudentlevel({
            id: this.statId,
            classNum: this.classNum,
          });
        }
        this.loading = false;
        this.studentLevelNameList = res.data.data;
        if (res.data.data.length) {
          this.online = res.data.data[0].name;
          this.optionValue = res.data.data[0].name;
        }
        this.setRatiosChart();
      } catch {
        this.loading = false;
      }
    },
    setRatiosChart() {
      let series = [];
      if (this.examType == 1) {
        let ratio = this.studentLevelNameList.find(
          (item) => item.name === this.online
        );
        // this.subIndex = this.studentLevelNameList.findIndex(
        //   (item) => item.name === this.optionValue
        // );
        series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: [
              ratio.classProportion || 0,
              ratio.previousClassProportion || 0,
              ratio.nextClassProportion || 0,
              ratio.no1ClassProportion || 0,
            ],
          },
          {
            name: "学校",
            type: "line",
            data: [
              ratio.schoolProportion || 0,
              ratio.schoolProportion || 0,
              ratio.schoolProportion || 0,
              ratio.schoolProportion || 0,
            ],
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体",
            type: "line",
            data: [
              ratio.unionProportion || 0,
              ratio.unionProportion || 0,
              ratio.unionProportion || 0,
              ratio.unionProportion || 0,
            ],
          });
        }
      } else {
        series = this.studentLevelNameList.map((item) => {
          return {
            name: item.name,
            type: "bar",
            stack: "ad",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: [item.classProportion, item.gradeProportion],
          };
        });
      }
      this.studentLevelOptions = {
        legend: {
          itemGap: 30,
          formatter: (name) => {
            if (!name) return "";
            return this.getEqualNewlineString(name, 10);
          },
        },
        xAxis: [
          {
            type: "category",
            data:
              this.examType == 1
                ? ["本班", "上位班级", "下位班级", "整体高位班级"]
                : ["班级", "年级"],
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: { show: false },
            splitLine: { show: true },
            axisLine: { show: false },
          },
        ],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        series: series,
      };
    },
    async getbzrreportclasscompetitiveTopData() {
      // 前N名
      try {
        this.loading = true;
        let res;
        if (this.examType == 1) {
          res = await UniongetbzrreportclasscompetitiveTop({
            id: this.statId,
            viewSchoolId: this.$route.query.schoolId,
            classNum: this.classNum,
          });
        } else {
          res = await getbzrreportclasscompetitiveTop({
            id: this.statId,
            classNum: this.classNum,
          });
        }

        this.loading = false;
        this.topList = res.data.data;
        if (res.data.data.length) {
          this.online = res.data.data[0].name;
          this.optionValue = res.data.data[0].name;
        }
        this.setTopsetctionChart();
      } catch {
        this.loading = false;
      }
    },
    setTopsetctionChart() {
      let series = [];
      if (this.examType == 1) {
        let topSections = this.topList.find((item) => item.name == this.online);
        // this.subIndex = this.topList.findIndex(
        //   (item) => item.name === this.optionValue
        // );
        series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: [
              topSections.classTotal,
              topSections.previousClassTotal,
              topSections.nextClassTotal,
              topSections.no1ClassTotal,
            ],
          },
          {
            name: "学校",
            type: "line",
            data: [
              topSections.schoolTotal,
              topSections.schoolTotal,
              topSections.schoolTotal,
              topSections.schoolTotal,
            ],
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体",
            type: "line",
            data: [
              topSections.unionTotal,
              topSections.unionTotal,
              topSections.unionTotal,
              topSections.unionTotal,
            ],
          });
        }
      } else {
        series = [
          {
            name: "人数",
            type: "bar",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: this.topList.map((item) => item.total),
          },
        ];
      }
      this.topOptions = {
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        xAxis: [
          {
            type: "category",
            data:
              this.examType == 1
                ? ["本班", "上位班级", "下位班级", "整体高位班级"]
                : this.topList.map((item) => item.name),
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
          },
        ],
        legend: { itemGap: 30 },
        yAxis: [
          {
            type: "value",
            axisTick: { show: false },
            splitLine: { show: true },
            axisLine: { show: false },
          },
        ],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },

        series: [...series],
      };
    },
    async getbzrreportclasscompetitiveOtherData() {
      // 其他指标
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await UniongetbzrreportclasscompetitiveOther({
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
            classNum: this.classNum,
          });
        } else {
          res = await getbzrreportclasscompetitiveOther({
            id: this.statId,
            classNum: this.classNum,
          });
        }

        this.loading = false;
        this.tableData = res.data.data;
        if (this.topData.isSchoolDisplayReportUnionAverageScore != 1) {
          this.tableData = res.data.data.filter((item) => item.name !== "整体");
        }
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch {
        this.loading = false;
      }
    },
    typeChange() {
      this.subIndex = 0;
      switch (this.type) {
        case 1:
          this.getbzrreportclasscompetitiveOnlineData();
          break;
        case 2:
          this.getbzrreportclasscompetitiveStudentlevelData();
          break;
        case 3:
          this.getbzrreportclasscompetitiveTopData();
          break;
        case 4:
          this.getbzrreportclasscompetitiveOtherData();
          break;
      }
    },
    getTypeLabel(type) {
      const label =
        (type === 1 && "班级数据") ||
        (type === 2 && "年级数据") ||
        (type === 3 && "差值");
      return label;
    },
    prev() {
      this.subIndex = (this.subIndex <= 0 && 0) || this.subIndex - 1;
    },
    next() {
      this.subIndex =
        (this.subIndex >= this.onlineData.length - 1 &&
          this.onlineData.length - 1) ||
        this.subIndex + 1;
    },
    prevLevel() {
      this.subIndex = (this.subIndex <= 0 && 0) || this.subIndex - 1;
    },
    nextLevel() {
      this.subIndex =
        (this.subIndex >= this.studentLevelNameList.length - 1 &&
          this.studentLevelNameList.length - 1) ||
        this.subIndex + 1;
    },
    prevTop() {
      this.subIndex = (this.subIndex <= 0 && 0) || this.subIndex - 1;
    },
    nextTop() {
      this.subIndex =
        (this.subIndex >= this.topList.length - 1 && this.topList.length - 1) ||
        this.subIndex + 1;
    },
    olineAnalyChange() {
      let online;
      let series;
      if (this.examType == 1) {
        online = this.onlineData.find((item) => item.name === this.online);
        series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: [
              online.classProportion,
              online.previousClassProportion,
              online.nextClassProportion,
              online.no1ClassProportion,
            ],
          },
          {
            name: "学校",
            type: "line",
            data: [
              online.schoolProportion,
              online.schoolProportion,
              online.schoolProportion,
              online.schoolProportion,
            ],
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体",
            type: "line",
            data: [
              online.unionProportion,
              online.unionProportion,
              online.unionProportion,
              online.unionProportion,
            ],
          });
        }
        // if (val) {
        //   this.optionValue = val;
        // }
        // this.subIndex = this.onlineData.findIndex(
        //   (item) => item.name === this.optionValue
        // );
      } else {
        series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: this.onlineData.map(
              (item) =>
                item[
                  this.onlineAnalyseDataType === 1
                    ? "classProportion"
                    : "upTotal"
                ]
            ),
          },
        ];
      }
      let options = {
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        legend: {
          itemGap: 20,
        },
        xAxis: [
          {
            type: "category",
            data:
              this.examType == 1
                ? ["本班", "上位班级", "下位班级", "学科高位班级"]
                : this.onlineData.map((item) => item.name),
            axisTick: { show: false },
            splitLine: { show: false },
            axisLine: { show: false },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              rotate: 25,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: { show: false },
            splitLine: { show: true },
            axisLine: { show: false },
            name: this.onlineAnalyseDataType === 1 ? "上线率（%）" : "人数",
            nameTextStyle: {
              padding: [0, 0, 0, 12],
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        series: [...series],
      };
      if (this.onlineAnalyseDataType === 1 && this.$route.query.examType != 1) {
        let data = {
          name: "年级",
          type: "line",
          color: "#FF6833",
          label: {
            position: "top",
            show: false,
          },
          data: this.onlineData.map((item) => item.gradeProportion),
        };
        options.series.push(data);
      }
      this.onlineAnalyseDataOptions = options;
    },
    getEqualNewlineString(params, length) {
      let text = "";
      let count = Math.ceil(params.length / length); // 向上取整数
      // 一行展示length个
      if (count > 1) {
        for (let z = 1; z <= count; z++) {
          text += params.substr((z - 1) * length, length);
          if (z < count) {
            text += "\n";
          }
        }
      } else {
        text += params.substr(0, length);
      }
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
.competitive {
  .radio {
    display: flex;
    justify-content: flex-end;
  }

  .charts {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;

    &-item {
      flex: 1;
      box-sizing: border-box;
      height: 470px;
      line-height: 1;

      .space {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .top {
          height: 90%;
          box-sizing: border-box;

          .title {
            font-size: 14px;
            color: #080a09;

            margin-bottom: 24px;
          }

          .title-box {
            font-size: 14px;
            color: #080a09;
            white-space: nowrap;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .nameList {
            // display: grid;
            // grid-template-columns: repeat(auto-fill, 50px);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            &-item {
              width: 45px;
              margin-bottom: 24px;
              margin-right: 24px;
              color: #2474ed;
              font-size: 14px;
              text-decoration: underline;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .bottom {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 14px;
          color: #2474ed;
          box-sizing: border-box;
          padding: 0 24px;

          div {
            cursor: pointer;
          }

          .el-icon-arrow-left::before {
            font-size: 14px;
          }

          .el-icon-arrow-right::before {
            font-size: 14px;
          }
        }
      }

      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        box-sizing: border-box;
        height: 39px;

        .title {
          font-size: 14px;
          color: #080a09;
        }
      }
    }

    & :last-child {
      margin-right: 0;
    }
  }

  .gridCharts {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 20px 0;
    border: 1px solid #e9e9e9;
    border-radius: 4px;

    .gridCharts-item {
      .title {
        color: #080a09;
        font-size: 14px;
        padding: 0 24px;

        margin-bottom: 33px;

        span {
          color: #8b8b8b;
          font-size: 14px;
          margin-left: 24px;
        }
      }

      .space {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .top {
          height: 90%;
          box-sizing: border-box;

          // padding: 0 24px;
          .title {
            font-size: 14px;
            color: #080a09;

            margin-bottom: 24px;
          }

          .title-box {
            font-size: 14px;
            color: #080a09;

            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .nameList {
            // display: grid;
            // grid-template-columns: repeat(auto-fill, 50px);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            &-item {
              width: 45px;
              margin-bottom: 24px;
              margin-right: 24px;
              color: #2474ed;
              font-size: 14px;
              text-decoration: underline;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .bottom {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 14px;
          color: #2474ed;
          box-sizing: border-box;
          padding: 0 24px;

          div {
            cursor: pointer;
          }

          .el-icon-arrow-left::before {
            font-size: 14px;
          }

          .el-icon-arrow-right::before {
            font-size: 14px;
          }
        }
      }
    }

    .padding24 {
      padding: 24px;
    }

    & :last-child {
      margin-right: 0;
    }
  }
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}

.mb36 {
  margin-bottom: 36px;
}

.dott-line {
  margin: 0 20px;
  border: 1px dashed #e6e6e6;
  padding: 10px;
}
</style>
