export const enums = {
  1: `跟踪生：仅当在报告设置中导入了跟踪生且跟踪生对应成功才显示。跟踪生（目标跟踪生）即为实现上线目标最稳定的学生群体，为目标基础保底儿。可从两方面设置，一、（基于目标）结合学生入口成绩及（中）高考预期，确定学校跟踪生名单，各班数量不均。二、（基于管理）每班确定N名跟踪生，多为成绩稳定的尖子生，每班数量均匀。`,
  2: `临界生：仅当统计参数设置中进行了上线设置才显示。临界生即为处于上线边缘的学生，分为上线临界生（刚刚上线的学生）及未上线临界生（马上要上线的学生），稳定上线临界生成绩，提升未上线临界生成绩，保证上线率稳步提升。`,
  3: `波动生：以某次考试为对比入口，检索出波动幅度较大的学生，分为上升波动（处于成绩提升趋势）及下降波动（处于成绩下降趋势）。对上升波动的学生给予肯定，激励其保持上升趋势；对下降波动学生，辅助分析原因并给予调整建议和鼓励。特别是排名靠前或临界状态的学生，可进一步查看学生报告了解历次发展情况，进行波动性深度分析。`,
  4: `拔尖生（优生劣科）：了解拔尖生群体分布情况，以及优生劣科情况（拔尖生存在“严重偏科”的学科）。优生劣科可结合学生自身的学习特点分析，是否在本学科存在学有余力但方法不当或兴趣不高的情况，如存在应适当引导学生以总分为前提进行偏科学科的学习方法或学习状态的调整，同时也可提升班级总分高分群体占比。`,
  5: `后进生（潜力学科）：了解后进生群体分布情况，以及潜力学科情况（后进生存在单科排名大幅度优于总分排名的学科）。后进生的潜力学科，可依据学生情况针对性分析，是否存在学有余力但学习动力不足的问题导致仅兴趣或天赋学科成绩较好，其他学科成绩滞后的情况。如存在应采取相应手段激发学生学习动力，并给予潜力学科肯定，如给予学生该兴趣学科的生涯规划指导等。`,
};
export const waveLabel = [
  {
    label: "姓名",
    prop: "name",
  },
  {
    label: "总分",
    prop: "total",
  },
  {
    label: "校排名",
    prop: "schoolRank",
  },
  {
    label: "增幅",
    prop: "increase",
  },
];
