<template>
  <div ref="subject" v-loading="loading" class="subject card">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 2,
        classNum: classNum,
      }"
    >
      <div class="section-title">优劣势学科</div>
    </exportBtn>
    <div class="operate">
      <div class="filter">
        <div v-if="isShowSelect()" class="selects-item">
          选择指标：
          <el-select
            v-model="otherType"
            style="width: 174px"
            placeholder="请选择"
            @change="otherSelectChange"
          >
            <el-option
              v-for="item in otherOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-radio-group v-model="category" size="small" @change="radioChange">
        <el-radio-button :label="1">平均分</el-radio-button>
        <el-radio-button
          v-isUnionShow="{ field: 'topInfoOnlineVOs', type: 'length' }"
          :label="2"
          >上线情况</el-radio-button
        >
        <el-radio-button :label="3">比率</el-radio-button>
        <el-radio-button :label="4">前N名</el-radio-button>
        <el-radio-button :label="5">其他指标</el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart-box">
      <div class="chart">
        <chart
          v-if="chartOptions"
          :key="chartKey"
          :data="chartOptions"
          :height="500"
        />
        <no-data v-else></no-data>
      </div>
      <div v-loading="loading" class="tableList">
        <el-table
          ref="elTable"
          :key="tableKey"
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column label="学科" prop="subjectName" fixed align="center">
          </el-table-column>
          <template v-if="category === 1">
            <el-table-column prop="statTotal" label="统计人数" align="center">
            </el-table-column>
            <el-table-column prop="averageScore" label="平均分" align="center">
            </el-table-column>
            <el-table-column
              v-if="topData.isSchoolDisplayReportClassRank == 1"
              prop="schoolRank"
              label="校排名"
              align="center"
            >
              <template slot-scope="{ row }">
                <span :class="row.isSchoolRankRed == 1 ? 'ft-red' : ''">{{
                  row.schoolRank
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="topData.isSchoolDisplayReportClassRank == 1"
              prop="unionRank"
              label="整体排名"
              align="center"
            >
              <template slot-scope="{ row }">
                <span :class="row.isUnionRankRed == 1 ? 'ft-red' : ''">{{
                  row.unionRank
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="classScoreRate"
              label="得分率"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.classScoreRate }}
              </template>
            </el-table-column>
            <el-table-column
              prop="gradeScoreRate"
              label="得分率（学校）"
              width="85"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.gradeScoreRate }}
              </template>
            </el-table-column>

            <el-table-column prop="difference" label="差值" align="center">
              <template slot-scope="{ row }">
                <span
                  :class="
                    row.difference < 0
                      ? 'ft-red'
                      : row.difference > 0
                      ? 'ft-green'
                      : ''
                  "
                  >{{ row.difference }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              v-if="topData.isSchoolDisplayReportUnionAverageScore == 1"
              prop="unionScoreRate"
              label="得分率（整体）"
              width="85"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.unionScoreRate }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="topData.isSchoolDisplayReportUnionAverageScore == 1"
              prop="unionDifference"
              label="差值"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  :class="
                    row.unionDifference < 0
                      ? 'ft-red'
                      : row.unionDifference > 0
                      ? 'ft-green'
                      : ''
                  "
                  >{{ row.unionDifference }}</span
                >
              </template>
            </el-table-column>
          </template>
          <template v-if="category === 2">
            <el-table-column
              prop="examTotal"
              label="参考人数"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="ep" label="有效分" align="center">
            </el-table-column>
            <el-table-column
              prop="total"
              label="上线人数"
              align="center"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="proportion"
              label="上线率"
              width="72"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row.proportion }} </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="topData.isSchoolDisplayReportClassRank == 1"
              prop="schoolRank"
              label="校排名"
              width="72"
              align="center"
            >
              <template slot-scope="{ row }">
                <span :class="row.isSchoolRankRed == 1 ? 'ft-red' : ''">{{
                  row.schoolRank
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="topData.isSchoolDisplayReportClassRank == 1"
              prop="schoolRank"
              label="整体排名"
              width="130"
              align="center"
            >
              <template slot-scope="{ row }">
                <span :class="row.isUnionRankRed == 1 ? 'ft-red' : ''">{{
                  row.unionRank
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="gradeProportion"
              label="年级上线率"
              width="130"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row.gradeProportion }} </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="effectiveTotal"
              label="有效上线人数(单科总分上线)"
              width="130"
              align="center"
            >
              <template slot="header">
                <div>有效上线人数</div>
                <div>(单科总分上线)</div>
              </template>
            </el-table-column>
            <el-table-column prop="effectiveRate" width="150" align="center">
              <template slot="header">
                <div>命中率</div>
                <div>(双上/总分上线)</div>
              </template>
              <template slot-scope="{ row }">
                <span>{{ row.effectiveRate }} </span>
              </template>
            </el-table-column>
            <el-table-column prop="contributionRate" width="150" align="center">
              <template slot="header">
                <div>贡献率</div>
                <div>(双上/总分上线)</div>
              </template>
              <template slot-scope="{ row }">
                <span>{{ row.contributionRate }} </span>
              </template>
            </el-table-column>
            <el-table-column
              :key="9"
              prop="contributionLevel"
              label="贡献等级"
              width="200"
              align="center"
            >
              <template slot-scope="{ row }">
                <span :class="row.contributionLevel">{{
                  row.contributionLevel
                }}</span>
              </template>
            </el-table-column>
          </template>
          <template v-if="category === 3">
            <el-table-column
              prop="statTotal"
              label="统计人数"
              width="100"
              align="center"
            >
            </el-table-column>
            <template v-if="examType != 1">
              <div
                v-for="(item, index) in ratioData"
                :key="'ratioData' + index"
              >
                <el-table-column :label="item.name" align="center" width="100">
                  <template slot-scope="{ row }">
                    <span>{{ row.ratios[index].proportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="校排名" align="center">
                  <template slot-scope="{ row }">
                    <span>{{ row.ratios[index].schoolRank }}</span>
                  </template>
                </el-table-column>
              </div>
            </template>
            <template v-else>
              <el-table-column :label="ratioName" align="center">
                <el-table-column label="本班（%）" align="center" width="100">
                  <template slot-scope="{ row }">
                    <span>{{ row.proportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="topData.isSchoolDisplayReportClassRank == 1"
                  label="校排名"
                  align="center"
                  width="100"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.schoolRank }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="topData.isSchoolDisplayReportClassRank == 1"
                  label="整体排名"
                  align="center"
                  width="100"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.unionRank }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="学校（%）" align="center" width="100">
                  <template slot-scope="{ row }">
                    <span>{{ row.gradeProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="topData.isSchoolDisplayReportUnionAverageScore == 1"
                  label="整体（%）"
                  align="center"
                  width="100"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.unionProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="showCol('previousClassProportion')"
                  label="上位班级（%）"
                  align="center"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.previousClassProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="showCol('nextClassProportion')"
                  label="下位班级（%）"
                  align="center"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.nextClassProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="整体高位班级（%）"
                  align="center"
                  width="180"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.no1ClassProportion }}</span>
                  </template>
                </el-table-column>
              </el-table-column>
            </template>
          </template>
          <template v-if="category === 4">
            <el-table-column
              prop="statTotal"
              label="统计人数"
              width="100"
              align="center"
            >
            </el-table-column>

            <template v-if="examType != 1">
              <el-table-column
                v-for="(item, index) in topSections"
                :key="'topSections' + index"
                :label="item.name + '(全校)'"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.topSections[index].total }}</span>
                </template>
              </el-table-column>
            </template>
            <template v-else>
              <el-table-column :label="ratioName" align="center">
                <el-table-column label="本班（%）" align="center" width="100">
                  <template slot-scope="{ row }">
                    <span>{{ row.proportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="topData.isSchoolDisplayReportClassRank == 1"
                  label="校排名"
                  align="center"
                  width="100"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.schoolRank }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="topData.isSchoolDisplayReportClassRank == 1"
                  label="整体排名"
                  align="center"
                  width="100"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.unionRank }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="学校（%）" align="center" width="100">
                  <template slot-scope="{ row }">
                    <span>{{ row.gradeProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="topData.isSchoolDisplayReportUnionAverageScore == 1"
                  label="整体（%）"
                  align="center"
                  width="100"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.unionProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="showCol('previousClassProportion')"
                  label="上位班级（%）"
                  align="center"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.previousClassProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="showCol('nextClassProportion')"
                  label="下位班级（%）"
                  align="center"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.nextClassProportion }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="整体高位班级（%）"
                  align="center"
                  width="180"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.no1ClassProportion }}</span>
                  </template>
                </el-table-column>
              </el-table-column>
            </template>
          </template>
          <template v-if="category === 5">
            <el-table-column prop="statTotal" label="统计人数" align="center">
            </el-table-column>
            <template v-if="examType == 1">
              <template v-if="otherType == 3">
                <el-table-column
                  label="本班平均分"
                  align="center"
                  prop="classAverageScore"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  label="整体平均分"
                  align="center"
                  prop="unionAverageScore"
                  width="100"
                >
                </el-table-column>
              </template>
              <el-table-column :label="ratioName" align="center">
                <el-table-column
                  label="本班"
                  align="center"
                  prop="classIndex"
                  width="100"
                >
                </el-table-column>
                <el-table-column label="学校" align="center" width="120">
                  <template slot-scope="{ row }">
                    <span>{{ row.schoolIndex }}</span>
                    <span v-if="row.schoolIndexDiff > 0" style="color: #55d497"
                      >+{{ row.schoolIndexDiff }}</span
                    >
                    <span
                      v-if="row.schoolIndexDiff < 0"
                      style="color: #fc7070"
                      >{{ row.schoolIndexDiff }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="topData.isSchoolDisplayReportUnionAverageScore == 1"
                  label="整体"
                  align="center"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.unionIndex || "-" }}</span>
                    <span
                      :class="
                        row.unionIndexDiff < 0
                          ? 'ft-red'
                          : row.unionIndexDiff > 0
                          ? 'ft-green'
                          : ''
                      "
                      ><span v-if="row.unionIndexDiff > 0">+</span
                      >{{ row.unionIndexDiff }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="showCol('previousClassIndex')"
                  label="上位班级"
                  align="center"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.previousClassIndex }}</span>
                    <span
                      v-if="row.previousClassIndexDiff"
                      :class="
                        row.previousClassIndexDiff < 0
                          ? 'ft-red'
                          : row.previousClassIndexDiff > 0
                          ? 'ft-green'
                          : ''
                      "
                      ><span v-if="row.previousClassIndexDiff > 0">+</span
                      >{{ row.previousClassIndexDiff }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="showCol('nextClassIndex')"
                  label="下位班级"
                  align="center"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.nextClassIndex }}</span>
                    <span
                      v-if="row.nextClassIndexDiff"
                      :class="
                        row.nextClassIndexDiff < 0
                          ? 'ft-red'
                          : row.nextClassIndexDiff > 0
                          ? 'ft-green'
                          : ''
                      "
                      ><span v-if="row.nextClassIndexDiff > 0">+</span
                      >{{ row.nextClassIndexDiff }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="整体高位班级"
                  align="center"
                  width="130"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.no1ClassIndex }}</span>
                    <span
                      v-if="row.no1ClassIndexDiff"
                      :class="
                        row.no1ClassIndexDiff < 0
                          ? 'ft-red'
                          : row.no1ClassIndexDiff > 0
                          ? 'ft-green'
                          : ''
                      "
                      ><span v-if="row.no1ClassIndexDiff > 0">+</span
                      >{{ row.no1ClassIndexDiff }}</span
                    >
                  </template>
                </el-table-column>
              </el-table-column>
            </template>
            <template v-else>
              <el-table-column prop="index" :label="getLabel()" align="center">
              </el-table-column>
              <el-table-column
                prop="gradeIndex"
                :label="getLabel() + '（学校）'"
                align="center"
              >
              </el-table-column>
              <el-table-column
                :label="otherType != 3 ? '差值' : '超均率（学校）'"
                prop="indexDiff"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span :class="row.indexDiff > 0 ? 'on' : 'off'">{{
                    row.indexDiff
                  }}</span>
                </template>
              </el-table-column>
            </template>
          </template>
        </el-table>
      </div>
      <div v-if="category === 2" class="model-desc mt10">
        <div>
          <span class="A">A：</span>命中率 ≥ 60%，贡献率 ≥ 70%
          结论：单科与总分上线匹配较好
        </div>
        <div>
          <span class="B">B：</span>命中率 ＜ 60%，贡献率 ≥ 70%
          结论：单科上线优势或单科冒进 原因：1. 单科教学有效性高 2. 单科投入过多
        </div>
        <div>
          <span class="C">C：</span>命中率 ≥ 60%，贡献率 ＜ 70% 结论：单科滞后
          原因：1. 单科教学有效性较低 2. 单科投入不足
        </div>
        <div>
          <span class="D">D：</span>命中率 ＜ 60%，贡献率 ＜ 70%
          结论：单科上线与总分上线匹配不好
        </div>
        <div class="ps">注：1.本报告中上线有关计算的统计基数均为参考人数</div>
        <div class="ps">
          2.有效上线人数指单科与总分同时上线的人数。命中率为单科有效上线人数/单科上线人数；非选考科目的贡献率为单科有效上线人数/总分上线人数，选考科目的贡献率为单科有效上线人数/选考该科且总分上线人数。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getbzrreportsubjectAveragescore,
  getbzrreportsubjectOnline,
  getstatonlineoption,
  getbzrreportsubjectRatio,
  getbzrreportsubjectTop,
  getbzrreportsubjectOther,
  exportHeadReport,
} from "@/core/api/academic/headReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import {
  UniongetbzrreportsubjectAveragescore,
  UniongetbzrreportsubjectOnline,
  UniongetbzrreportsubjectRatio,
  UniongetbzrreportsubjectTop,
  UniongetbzrreportsubjectOther,
} from "@/core/api/academic/unionReport";
import {
  getstatsubjectoption,
  getstatratiooption,
  getstattopsectionoption,
} from "@/core/api/academic/common";
import chart from "./chart.vue";
export default {
  name: "Subject",
  components: {
    chart,
    exportBtn,
  },
  props: {
    classNum: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requestFunc: exportHeadReport,
      category: 1,
      chartOptions: {},
      chartKey: Math.random(),
      tableData: [],
      statId: this.$route.query.id,
      subjectList: [],
      loading: false,
      otherType: "",
      otherOptions: [],
      ratioData: [],
      topSections: [],
      ratioName: null,
      unobserve: false,
      tableKey: Math.random(),
      io: {},
      examType: this.$route.query.examType,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.classNum && this.classNum != "0") {
        this.getstatinfoList();
      }
    });
  },
  methods: {
    isShowSelect() {
      if (this.examType == 1) {
        return [2, 3, 4, 5].includes(this.category);
      } else {
        return [2, 5].includes(this.category);
      }
    },
    showCol(field) {
      let result = this.tableData.every((item) => item[field] !== null);
      return result;
    },
    getLabel() {
      let obj = {
        1: "最高分",
        2: "最低分",
        3: "平均分",
        4: "标准差",
      };
      return obj[this.otherType];
    },
    radioChange() {
      this.loading = true;
      switch (this.category) {
        case 1:
          this.getbzrreportsubjectAveragescoreData();
          break;
        case 2:
          this.getstatonlineoptionData();
          break;
        case 3:
          this.getstatratiooptionData();
          break;
        case 4:
          this.getstattopsectionoptionData();
          break;
        case 5:
          this.otherOptions = [
            {
              name: "最高分",
              id: 1,
            },
            {
              name: "最低分",
              id: 2,
            },
            {
              name: "超均率",
              id: 3,
            },
            {
              name: "标准差",
              id: 4,
            },
          ];
          this.otherType = 1;
          this.otherSelectChange();
          break;
      }
    },
    getLevelColor(row) {
      if (row.onlineRateEffective >= 60 && row.contributionRate >= 70) {
        return "A";
      } else if (row.onlineRateEffective < 60 && row.contributionRate >= 70) {
        return "B";
      } else if (row.onlineRateEffective >= 60 && row.contributionRate < 70) {
        return "C";
      } else if (row.onlineRateEffective < 60 && row.contributionRate < 70) {
        return "D";
      }
    },
    otherSelectChange() {
      this.chartOptions = null;
      if (this.category === 2) {
        this.getbzrreportsubjectOnlineData();
      } else if (this.category === 3) {
        this.ratioName = this.otherOptions.find(
          (item) => item.id === this.otherType
        ).name;
        this.getbzrreportsubjectRatioData();
      } else if (this.category === 4) {
        this.ratioName = this.otherOptions.find(
          (item) => item.id === this.otherType
        ).name;
        this.getbzrreportsubjectTopData();
      } else if (this.category === 5) {
        this.ratioName = this.otherOptions.find(
          (item) => item.id === this.otherType
        ).name;
        this.getbzrreportsubjectOtherData();
      }
    },
    async getstatinfoList() {
      const res = await getstatsubjectoption({
        id: this.statId,
        hasTotal: 0,
        viewSchoolId: this.$route.query.schoolId,

        requestPage: 3,
      });
      this.subjectList = res.data.data.length && res.data.data;
      this.getbzrreportsubjectAveragescoreData();
    },
    async getbzrreportsubjectAveragescoreData() {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetbzrreportsubjectAveragescore({
          viewSchoolId: this.$route.query.schoolId,
          classNum: this.classNum,
          id: this.statId,
        });
      } else {
        res = await getbzrreportsubjectAveragescore({
          classNum: this.classNum,
          id: this.statId,
        });
      }
      let series = [
        {
          name: "班级",
          type: "bar",
          barMaxWidth: 40,
          data: res.data.data.map((item) => item.classScoreRate),
          label: {
            position: "top",
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
          },
        },
        {
          name: "年级",
          type: "line",
          color: "#FF9776",
          data: res.data.data.map((item) => item.gradeScoreRate),
          smooth: true,
          label: {
            position: "top",
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
          },
        },
      ];
      if (this.examType == 1) {
        series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            data: res.data.data.map((item) => item.classScoreRate),
            label: {
              position: "top",
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
            },
          },
          {
            name: "学校",
            type: "line",
            color: "#FF9776",
            data: res.data.data.map((item) => item.gradeScoreRate),
            smooth: true,
          },
          {
            name: "上位班级",
            type: "line",
            color: "#5D7092",
            data: res.data.data.map((item) => item.previousClassScoreRate),
            smooth: true,
          },
          {
            name: "下位班级",
            type: "line",
            color: "#E8684A",
            data: res.data.data.map((item) => item.nextClassScoreRate),
            smooth: true,
          },
          {
            name: "整体高位班级",
            type: "line",
            color: "#E8684A",
            data: res.data.data.map((item) => item.no1ClassScoreRate),
            smooth: true,
          },
        ];
        if (res.data.data.every((item) => item.nextClassScoreRate === null)) {
          series = series.filter((item) => item.name !== "下位班级");
        }
        if (
          res.data.data.every((item) => item.previousClassScoreRate === null)
        ) {
          series = series.filter((item) => item.name !== "上位班级");
        }
      }
      this.chartOptions = {
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        grid: {
          bottom: res.data.data.length > 25 ? "20%" : "5%",
          containLabel: true,
        },
        legend: { itemGap: 30 },
        dataZoom: [
          //滚动条
          {
            show: res.data.data.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: res.data.data.length - (res.data.data.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: res.data.data.map((item) => item.subjectName),
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 5));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: { show: false },
            splitLine: { show: true },
            name: "得分率",
          },
        ],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        series: [...series],
      };
      this.chartKey = Math.random();
      this.loading = false;
      this.tableData = res.data.data;
      this.tableKey = Math.random();
    },
    async getstatonlineoptionData() {
      this.loading = true;
      const res = await getstatonlineoption({
        id: this.statId,
      });
      this.otherOptions = res.data.data;
      if (res.data.data.length) {
        this.otherType = res.data.data[0].id;
        this.getbzrreportsubjectOnlineData();
      }

      this.loading = false;
    },
    async getstatratiooptionData() {
      this.loading = true;
      const res = await getstatratiooption({
        id: this.statId,
      });
      this.otherOptions = res.data.data;
      if (res.data.data.length) {
        this.otherType = res.data.data[0].id;
        this.otherSelectChange();
      }
      this.loading = false;
    },
    async getstattopsectionoptionData() {
      this.loading = true;
      const res = await getstattopsectionoption({
        id: this.statId,
      });
      this.otherOptions = res.data.data;
      if (res.data.data.length) {
        this.otherType = res.data.data[0].id;
        this.otherSelectChange();
      }
      this.loading = false;
    },
    async getbzrreportsubjectOnlineData() {
      try {
        this.loading = true;
        let res;
        let series = [];
        if (this.examType == 1) {
          res = await UniongetbzrreportsubjectOnline({
            classNum: this.classNum,
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
            onlineId: this.otherType,
          });
          series = [
            {
              name: "班级",
              type: "bar",
              barMaxWidth: 40,
              data: res.data.data.map((item) => item.proportion),
              label: {
                position: "top",
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
            {
              name: "学校",
              type: "line",
              color: "#FF9776",
              data: res.data.data.map((item) => item.gradeProportion),
              smooth: true,
            },
            {
              name: "上位班级",
              type: "line",
              color: "#5D7092",
              data: res.data.data.map((item) => item.previousClassProportion),
              smooth: true,
            },
            {
              name: "下位班级",
              type: "line",
              color: "#E8684A",
              data: res.data.data.map((item) => item.nextClassProportion),
              smooth: true,
            },
            {
              name: "整体高位班级",
              type: "line",
              color: "#E8684A",
              data: res.data.data.map((item) => item.no1ClassProportion),
              smooth: true,
            },
          ];
          if (
            res.data.data.every((item) => item.nextClassProportion === null)
          ) {
            series = series.filter((item) => item.name !== "下位班级");
          }
          if (
            res.data.data.every((item) => item.previousClassProportion === null)
          ) {
            series = series.filter((item) => item.name !== "上位班级");
          }
        } else {
          res = await getbzrreportsubjectOnline({
            classNum: this.classNum,
            id: this.statId,
            onlineId: this.otherType,
          });
          series = [
            {
              name: "班级",
              type: "bar",
              barMaxWidth: 40,
              data: res.data.data.map((item) => item.proportion),
              label: {
                position: "top",
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
            {
              name: "年级",
              type: "line",
              color: "#FF6833",
              smooth: true,
              data: res.data.data.map((item) => item.gradeProportion),
              label: {
                position: "top",
                show: true,
                textStyle: {
                  color: "#FF6833",
                },
              },
            },
          ];
        }

        if (res.data.data.length) {
          this.tableData = res.data.data;
          this.chartOptions = {
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
            legend: { itemGap: 30 },
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.subjectName),
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: { show: false },
                splitLine: { show: true },
                name: "上线率",
              },
            ],
            series: [...series],
          };
        }
        this.chartKey = Math.random();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getbzrreportsubjectRatioData() {
      try {
        let res;
        this.loading = true;
        if (this.examType == 1) {
          res = await UniongetbzrreportsubjectRatio({
            classNum: this.classNum,
            viewSchoolId: this.$route.query.schoolId,
            ratioId: this.otherType,
            id: this.statId,
          });
        } else {
          res = await getbzrreportsubjectRatio({
            classNum: this.classNum,
            id: this.statId,
          });
        }

        if (this.examType != 1) {
          this.tableData = res.data.data.map((item) => {
            return {
              ...item,
              ...item.ratios.find((it) => it.id === this.otherType),
            };
          });
          this.ratioData = res.data.data.length && res.data.data[0].ratios;

          const chartRatio =
            res.data.data.length && res.data.data[0].chartRatios;
          const data = chartRatio.map((item) => {
            const itemArr = this.tableData.map((it) =>
              it.chartRatios.find((i) => i.name == item.name)
            );
            return {
              name: item.name,
              value: itemArr.map((item) => item.proportion),
            };
          });
          this.chartOptions = {
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
            legend: {
              itemGap: 30,
            },
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.subjectName),
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: { show: false },
                splitLine: { show: true },
                name: "比率",
              },
            ],
            series: data.map((item) => {
              return {
                name: item.name,
                label: {
                  show: true,
                  position: "inside",
                  color: "#3E4551",
                  formatter: function (params) {
                    const { value } = params;
                    if (value > 0) {
                      return value;
                    } else {
                      return "";
                    }
                  },
                },
                type: "bar",
                barMaxWidth: 40,
                data: item.value,
                stack: "stack",
              };
            }),
          };
          this.chartKey = Math.random();
        } else {
          this.tableData = res.data.data;
          let series = [
            {
              name: "班级",
              type: "bar",
              barMaxWidth: 40,
              data: this.tableData.map((item) => item.proportion),
              label: {
                position: "top",
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
            {
              name: "学校",
              type: "line",
              color: "#FF9776",
              data: this.tableData.map((item) => item.gradeProportion),
              smooth: true,
            },
            {
              name: "上位班级",
              type: "line",
              color: "#5D7092",
              data: this.tableData.map((item) => item.previousClassProportion),
              smooth: true,
            },
            {
              name: "下位班级",
              type: "line",
              color: "#E8684A",
              data: this.tableData.map((item) => item.nextClassProportion),
              smooth: true,
            },
            {
              name: "整体高位班级",
              type: "line",
              color: "#E8684A",
              data: this.tableData.map((item) => item.no1ClassProportion),
              smooth: true,
            },
          ];
          if (
            res.data.data.every((item) => item.nextClassProportion === null)
          ) {
            series = series.filter((item) => item.name !== "下位班级");
          }
          if (
            res.data.data.every((item) => item.previousClassProportion === null)
          ) {
            series = series.filter((item) => item.name !== "上位班级");
          }
          this.chartOptions = {
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
            legend: {
              itemGap: 30,
            },
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.subjectName),
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: { show: false },
                splitLine: { show: true },
                name: "比率",
              },
            ],
            series: [...series],
          };
        }
        this.chartKey = Math.random();
        this.loading = false;

        this.tableKey = Math.random();
      } catch {
        this.loading = false;
      }
    },
    async getbzrreportsubjectTopData() {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetbzrreportsubjectTop({
          viewSchoolId: this.$route.query.schoolId,
          classNum: this.classNum,
          topSectionId: this.otherType,
          id: this.statId,
        });
      } else {
        res = await getbzrreportsubjectTop({
          classNum: this.classNum,
          id: this.statId,
        });
      }

      this.tableData = res.data.data;
      this.tableKey = Math.random();

      if (this.examType != 1) {
        this.topSections = res.data.data.length && res.data.data[0].topSections;
        const data = this.topSections.map((item) => {
          const itemArr = this.tableData.map((it) =>
            it.topSections.find((i) => i.name === item.name)
          );
          return {
            name: item.name,
            value: itemArr.map((item) => item.total),
          };
        });

        this.chartOptions = {
          legend: {
            itemGap: 30,
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          color: [
            "#7AB5EF",
            "#FF9776",
            "#6EDBA7",
            "#FFD766",
            "#A997FB",
            " #FD8585 ",
          ],
          xAxis: [
            {
              type: "category",
              data: res.data.data.map((item) => item.subjectName),
              axisTick: { show: false },
              splitLine: { show: false },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisTick: { show: false },
              splitLine: { show: true },
              name: "人数",
            },
          ],
          series: data.map((item) => {
            return {
              name: item.name,
              type: "bar",
              barMaxWidth: 40,
              data: item.value,
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
            };
          }),
        };
        this.chartKey = Math.random();
      } else {
        let data = res.data.data;
        let series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            data: data.map((item) => item.proportion),
            label: {
              position: "top",
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
            },
          },
          {
            name: "学校",
            type: "line",
            color: "#FF9776",
            data: data.map((item) => item.gradeProportion),
            smooth: true,
          },
          {
            name: "上位班级",
            type: "line",
            color: "#5D7092",
            data: data.map((item) => item.previousClassProportion),
            smooth: true,
          },
          {
            name: "下位班级",
            type: "line",
            color: "#E8684A",
            data: data.map((item) => item.nextClassProportion),
            smooth: true,
          },
          {
            name: "整体高位班级",
            type: "line",
            color: "#E8684A",
            data: data.map((item) => item.no1ClassProportion),
            smooth: true,
          },
        ];
        if (res.data.data.every((item) => item.nextClassProportion === null)) {
          series = series.filter((item) => item.name !== "下位班级");
        }
        if (
          res.data.data.every((item) => item.previousClassProportion === null)
        ) {
          series = series.filter((item) => item.name !== "上位班级");
        }
        this.chartOptions = {
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          legend: {
            itemGap: 30,
          },
          grid: {
            bottom: res.data.data.length > 25 ? "20%" : "5%",
            containLabel: true,
          },
          dataZoom: [
            //滚动条
            {
              show: res.data.data.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: res.data.data.length - (res.data.data.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          xAxis: [
            {
              type: "category",
              data: res.data.data.map((item) => item.subjectName),
              axisTick: { show: false },
              splitLine: { show: false },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
                formatter: (val) => {
                  let arr = val.split("");
                  let index = 0;
                  let newArray = [];
                  while (index < arr.length) {
                    let data = arr.slice(index, (index += 5));
                    newArray.push(data.join(""));
                  }
                  let str = newArray.reduce(
                    (str, item) => str + "\n" + item,
                    ""
                  );
                  return str;
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisTick: { show: false },
              splitLine: { show: true },
              name: "比率",
            },
          ],
          series: series,
        };
        this.chartKey = Math.random();
      }
      this.loading = false;
    },
    async getbzrreportsubjectOtherData() {
      this.loading = true;
      let res;
      let series = [];
      if (this.examType == 1) {
        res = await UniongetbzrreportsubjectOther({
          classNum: this.classNum,
          viewSchoolId: this.$route.query.schoolId,
          id: this.statId,
          type: this.otherType,
        });
      } else {
        res = await getbzrreportsubjectOther({
          classNum: this.classNum,
          id: this.statId,
          type: this.otherType,
        });
      }

      this.tableData = res.data.data;
      this.tableKey = Math.random();

      let arr = [];

      if (this.examType == 1) {
        arr = res.data.data.filter((item) => item.subjectId != 0);
        series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            data: arr.map((item) => item.classIndex),
            label: {
              position: "top",
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
            },
          },
          {
            name: "年级",
            type: "line",
            color: "#FF9776",
            smooth: true,
            data: arr.map((item) => item.schoolIndex),
          },
          {
            name: "上位班级",
            type: "line",
            smooth: true,
            data: arr.map((item) => item.previousClassIndex),
          },
          {
            name: "下位班级",
            type: "line",
            smooth: true,
            data: arr.map((item) => item.nextClassIndex),
          },
          {
            name: "整体高位班级 ",
            type: "line",
            smooth: true,
            data: arr.map((item) => item.no1ClassIndex),
          },
        ];
        if (res.data.data.every((item) => item.nextClassIndex === null)) {
          series = series.filter((item) => item.name !== "下位班级");
        }
        if (res.data.data.every((item) => item.previousClassIndex === null)) {
          series = series.filter((item) => item.name !== "上位班级");
        }
      } else {
        if (this.otherType != 3) {
          arr = res.data.data.filter((item) => item.subjectId != 0);
          series = [
            {
              name: "班级",
              type: "bar",
              barMaxWidth: 40,
              data: arr.map((item) => item.index),
              label: {
                position: "top",
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
            {
              name: "年级",
              type: "line",
              color: "#FF9776",
              smooth: true,
              data: arr.map((item) => item.gradeIndex),
              label: {
                position: "top",
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
          ];
        } else {
          arr = res.data.data;
          series = [
            {
              name: "年级",
              type: "line",
              color: "#7AB5EF",
              smooth: true,
              data: arr.map((item) => item.indexDiff),
              label: {
                position: "top",
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
          ];
        }
      }
      this.chartOptions = {
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        legend: { itemGap: 30 },
        grid: {
          bottom: arr.length > 25 ? "20%" : "5%",
          containLabel: true,
        },
        dataZoom: [
          //滚动条
          {
            show: arr.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: arr.length - (arr.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],

        xAxis: [
          {
            type: "category",
            data: arr.map((item) => item.subjectName),
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 5));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: { show: false },
            splitLine: { show: true },
            name: this.otherOptions.find((item) => item.id === this.otherType)
              .name,
          },
        ],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        series: series,
      };
      this.chartKey = Math.random();
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.subject {
  .tableList {
    ::v-deep .hover-row {
      // background-color: #fff5d7 !important;
      td {
        background-color: #fff5d7 !important;
      }
    }
  }

  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .filter {
      .select-item {
        width: 112px;
      }
    }
  }

  .chart {
    margin-bottom: 24px;
  }

  .tag {
    background-color: #f2f8fe;
    line-height: 2;
    box-sizing: border-box;
    padding: 12px 24px;
    margin: 24px 0;
    color: #737677;
    font-size: 14px;

    .A {
      color: #60d598;
    }

    .B {
      color: #63a8ea;
    }

    .C {
      color: #f1815b;
    }

    .D {
      color: #ff0001;
    }
  }
}

.A {
  color: #60d598;
}

.B {
  color: #63a8ea;
}

.C {
  color: #f1815b;
}

.D {
  color: #ff0001;
}

.on {
  color: #60d598;
}

.off {
  color: #ff0001;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
