<template>
  <div ref="paperAnalysis" v-loading="tableLoading" class="subject card">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 4,
        classNum: classNum,
      }"
    >
      <div class="section-title">试卷分析</div>
    </exportBtn>
    <div class="mt20">
      <el-tabs
        v-model="subjectId"
        style="margin-bottom: 24px"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in subjectList"
          :key="index"
          :label="item.name"
          :name="item.id.toString()"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="operate">
      <div class="section-sub-title">应答情况分析</div>
      <div class="radios">
        <el-radio-group
          v-model="status"
          style="margin-right: 24px"
          size="small"
          @change="statusChange"
        >
          <el-radio-button :label="1">图</el-radio-button>
          <el-radio-button :label="2">表</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="category"
          size="small"
          @change="categoryChange"
        >
          <el-radio-button v-if="hasKnowledge" :label="1"
            >知识点</el-radio-button
          >
          <!-- <el-radio-button :label="2">考察能力</el-radio-button> -->
          <el-radio-button :label="3">题目 </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div>
      <div v-if="status === 1 && category === 1">
        <radar v-if="pointOptions" :data="pointOptions" height="400px" />
        <no-data v-else></no-data>
      </div>
      <div v-if="status === 1 && category === 2">
        <radar v-if="scoreRate.length" :data="abilityOptions" height="400px" />
        <no-data v-else></no-data>
      </div>
      <div
        v-if="
          (status === 2 && category === 1) || (status === 2 && category === 2)
        "
      >
        <el-table
          ref="elTable"
          :data="tableData"
          border
          style="width: 100%; margin-bottom: 24px"
        >
          <el-table-column
            v-if="category === 1"
            prop="knowledgeName"
            label="知识点"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column
          v-if="category === 2"
          prop="ability"
          label="考察能力"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ abilityArr[row.ability - 1] }}
          </template>
        </el-table-column> -->
          <el-table-column
            prop="score"
            label="满分分值"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countQuestion"
            label="题量"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="questionOrders"
            label="题号"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="averageScore"
            label="平均分"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="scoreRate"
            label="得分率"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="schoolScoreRate"
            label="校得分率"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countRight"
            label="满分人数"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countZero"
            label="零分人数"
            width="120"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div v-if="old.length > 10" class="btns">
          <el-button type="text" @click="isExtendChange">{{
            isExtend ? "收起" : "查看更多"
          }}</el-button>
        </div>
      </div>

      <div v-if="status === 1 && category === 3" class="chart-box">
        <bar-chart :data="barChartOptions" :height="500" />
      </div>
      <div v-if="status === 2 && category === 3">
        <el-table ref="elTable" :data="tableData" border style="width: 100%">
          <el-table-column
            prop="questionOrder"
            label="题目"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="score"
            label="满分分值"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="averageScore"
            label="平均分"
            width="70"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="scoreRate"
            label="得分率"
            width="70"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="schoolScoreRate"
            label="得分率（年级）"
            width="90"
            align="center"
          >
            <template slot-scope="{ row }">
              <span
                :class="row.schoolScoreRate > row.scoreRate ? 'ft-red' : ''"
                >{{ row.schoolScoreRate }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="countZero"
            label="零分人数"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countRight"
            label="满分人数"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="客观题选项分布"
            width="200"
            prop="options"
            align="center"
          >
            <template slot-scope="{ row }">
              <div
                v-for="(item, index) in row.options"
                :key="'optionDistribution' + index"
                class="flex"
              >
                <div class="label">{{ item.item }}：</div>
                <div v-if="item.total > 0" class="out">
                  <div
                    class="progress"
                    :style="{
                      width:
                        getPercent(
                          item.total,
                          row.countWrong + row.countRight,
                          row.options
                        ) + 'px',
                    }"
                  ></div>
                </div>
                <div v-else class="none"></div>
                <div class="total">{{ item.total }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="countWrong" label="答错人数" align="center">
          </el-table-column>
          <el-table-column
            prop="wrongStudents"
            label="答错名单"
            align="left"
            width="200"
          >
            <template slot-scope="{ row }">
              <div class="namList">
                <template v-for="(item, index) in row.wrongStudents">
                  <span
                    v-if="showStudentList(index, row)"
                    :key="index"
                    :class="item.type === 1 ? 'green' : 'red'"
                    @click="studentDetail(item)"
                    >{{ item.name }}</span
                  >
                </template>
                <el-button
                  v-if="row.wrongStudents.length > 5"
                  type="text"
                  @click="moreStudent(row)"
                  >{{ row.showMore ? "收起" : "查看更多" }}</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="pointOptions"
            prop="knowledgeNames"
            label="知识点"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column prop="ability" label="考察能力" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.ability === 1">易</span>
            <span v-if="row.ability === 2">中</span>
            <span v-if="row.ability === 3">难</span>
          </template>
        </el-table-column> -->
        </el-table>
        <div v-if="old.length > 10" class="btns">
          <el-button type="text" @click="isExtendChange">{{
            isExtend ? "收起" : "查看更多"
          }}</el-button>
        </div>
      </div>
      <div v-if="status == 2" class="tag">
        注：标红得分率表示班级得分率低于校得分率（或整体得分率）
      </div>
    </div>
    <div class="section-sub-title">学生应答反馈</div>
    <header-table
      v-loading="loading"
      :top-data="topData"
      :data="headerTableData"
    />
    <div class="tag">
      注：客观题显示的是学生的答错选项，主观题显示的是学生失分分值
    </div>
  </div>
</template>

<script>
import radar from "./radar.vue";
import barChart from "./chart.vue";
import headerTable from "./headerTable.vue";
import {
  getbzrreportpaperanalysisKnowledgepoints,
  getbzrreportpaperanalysisInvestigationability,
  getbzrreportpaperanalysisQuestionorder,
  getbzrreportpaperanalysisResponsefeedback,
  getbzrreportpaperanalyKnowledge,
  exportHeadReport,
} from "@/core/api/academic/headReport";
import {
  UniongetbzrreportpaperanalysisQuestionorder,
  UniongetbzrreportpaperanalysisKnowledgepoints,
  UniongetbzrreportpaperanalysisResponsefeedback,
} from "@/core/api/academic/unionReport";
import { getstatsubjectoption } from "@/core/api/academic/common";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  name: "PaperAnalysis",

  components: {
    radar,
    barChart,
    headerTable,
    exportBtn,
  },
  props: {
    classNum: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      subjectId: "",
      status: 1,
      requestFunc: exportHeadReport,
      category: 3,
      pointOptions: null,
      tableData: [{ label: "1" }],
      abilityOptions: {},
      barChartOptions: {},
      statId: this.$route.query.id,
      subjectList: [],
      abilityArr: ["易", "中", "难 "],
      headerTableData: [],
      unobserve: false,
      io: {},
      loading: false,
      containerLoading: false,
      tableLoading: false,
      scoreRate: [],
      schoolScoreRate: [],
      isExtend: false,
      old: [],
      hasKnowledge: null,

      examType: this.$route.query.examType,
    };
  },

  mounted() {
    this.$nextTick(() => {
      if (this.classNum && this.classNum != "0") {
        this.getstatinfoList();
      }
    });
  },
  methods: {
    moreStudent(item) {
      if (item.showMore) {
        this.$set(item, "showMore", false);
      } else {
        this.$set(item, "showMore", true);
      }
    },
    showStudentList(index, row) {
      if (row.showMore) {
        return true;
      } else if (!row.showMore && index < 5) {
        return true;
      } else {
        return false;
      }
    },
    isExtendChange() {
      this.isExtend = !this.isExtend;
      this.tableData = this.isExtend ? this.old : this.old.slice(0, 12);
    },
    studentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentId: item.studentId,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
    getPercent(num, total, options) {
      let totalNumber = options.reduce((total, item) => {
        return total + Number(item.total);
      }, 0);
      if (!num || !total) {
        return 0;
      } else {
        return (num / totalNumber) * 80;
      }
    },
    categoryChange() {
      if (this.category === 1 && this.status === 1) {
        this.getbzrreportpaperanalysisKnowledgepointsData();
      } else if (this.category === 1 && this.status === 2) {
        this.getbzrreportpaperanalyKnowledgeData();
      }
      // else if (this.category === 2) {
      //   this.getbzrreportpaperanalysisInvestigationabilityData();
      // }
      else if (this.category === 3) {
        this.getbzrreportpaperanalysisQuestionorderData();
      }
    },
    statusChange() {
      this.categoryChange();
    },
    formatProgress() {
      return "";
    },
    handleClick() {
      this.hasKnowledge = this.topData.subjectKnowledges.find(
        (item) => item.subjectId == this.subjectId
      ).hasKnowledge;
      this.categoryChange();
      this.getbzrreportpaperanalysisResponsefeedback();
    },
    async getbzrreportpaperanalyKnowledgeData() {
      this.tableLoading = true;
      const res = await getbzrreportpaperanalyKnowledge({
        classNum: this.classNum,
        id: this.statId,
        subjectId: this.subjectId,
      });
      this.tableData = res.data.data.slice(0, 12);
      this.old = res.data.data;
      this.isExtend = false;
      this.tableLoading = false;
      if (this.$refs.elTable) {
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      }
    },
    async getstatinfoList() {
      this.tableLoading = true;
      const res = await getstatsubjectoption({
        id: this.statId,
        hasTotal: 0,
        requestPage: 3,
        viewSchoolId: this.$route.query.schoolId,
      });
      const data = res.data.data.filter((item) => {
        //去除合并科目
        if ((item.type !== 2) & (item.type !== 3)) {
          return item;
        }
      });
      this.subjectList = [...data];
      this.subjectId =
        this.subjectList.length && this.subjectList[0].id.toString();
      this.hasKnowledge = this.topData.subjectKnowledges.find(
        (item) => item.subjectId == this.subjectId
      ).hasKnowledge;
      // this.getbzrreportpaperanalysisKnowledgepointsData();
      this.categoryChange();
      this.getbzrreportpaperanalysisResponsefeedback();
      this.tableLoading = false;
    },
    async getbzrreportpaperanalysisKnowledgepointsData() {
      this.tableLoading = true;
      this.scoreRate = [];
      this.schoolScoreRate = [];
      let res;
      let resData;
      if (this.examType === 1) {
        res = await UniongetbzrreportpaperanalysisKnowledgepoints({
          classNum: this.classNum,
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
          subjectId: this.subjectId,
        });
        resData = res.data.data;
      } else {
        res = await getbzrreportpaperanalysisKnowledgepoints({
          classNums: this.classNum,
          id: this.statId,
          subjectId: this.subjectId,
        });
        resData =
          res.data.data
            .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeVOs;
      }

      this.tableLoading = false;

      if (resData.length > 0) {
        this.scoreRate = resData.map((item) => {
          if (this.examType == 1) {
            return item.scoreRate;
          } else {
            return item
              .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeClassVOs[0]
              .classScoreRate;
          }
        });
        this.schoolScoreRate = resData.map((item) => item.schoolScoreRate);
        this.pointOptions = {
          radar: {
            shape: "circle",
            indicator: resData.map((item) => {
              return { name: item.name || item.knowledgeName || "", max: 100 };
            }),
          },
          legend: {
            itemGap: 35,
          },
          series: [
            {
              name: "知识点",
              type: "radar",
              data: [
                {
                  value: this.scoreRate,
                  name: "班级得分率",
                },
                {
                  value: this.schoolScoreRate,
                  name: "年级得分率",
                },
              ],
            },
          ],
        };
        this.tableData = resData.slice(0, 12);
        this.loading = false;
        this.old = resData;
        this.isExtend = false;
      } else {
        this.pointOptions = null;
        this.loading = this.tableLoading = false;
        this.tableData = [];
        // this.categoryChange();
      }
    },
    async getbzrreportpaperanalysisInvestigationabilityData() {
      this.scoreRate = [];
      this.schoolScoreRate = [];
      const res = await getbzrreportpaperanalysisInvestigationability({
        classNum: this.classNum,
        id: this.statId,
        subjectId: this.subjectId,
      });
      this.scoreRate = res.data.data.map((item) => item.scoreRate);
      this.schoolScoreRate = res.data.data.map((item) => item.schoolScoreRate);
      this.abilityOptions = {
        legend: {
          itemGap: 35,
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        radar: {
          shape: "",
          indicator: res.data.data.map((item) => {
            return {
              name: this.abilityArr[item.ability - 1],
              max: 100,
            };
          }),
        },
        series: [
          {
            name: "得分率",
            type: "radar",
            data: [
              {
                value: this.scoreRate,
                name: "班级",
              },
              {
                value: this.schoolScoreRate,
                name: "年级",
              },
            ],
          },
        ],
      };

      this.tableData = res.data.data;
      this.tableData = res.data.data.slice(0, 12);
      this.isExtend = false;
      this.old = res.data.data;
      if (this.$refs.elTable) {
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      }
    },
    async getbzrreportpaperanalysisQuestionorderData() {
      this.tableLoading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetbzrreportpaperanalysisQuestionorder({
          classNum: this.classNum,
          viewSchoolId: this.$route.query.schoolId,
          id: this.statId,
          subjectId: this.subjectId,
        });
      } else {
        res = await getbzrreportpaperanalysisQuestionorder({
          classNum: this.classNum,
          id: this.statId,
          subjectId: this.subjectId,
        });
      }

      this.tableLoading = false;
      let xAxis = res.data.data.map((item) => item.questionOrder);
      this.barChartOptions = {
        legend: {
          itemGap: 35,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        grid: {
          bottom: xAxis.length > 25 ? "25%" : "15%",
          containLabel: true,
        },
        dataZoom: [
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisLine: { show: true, lineStyle: { color: "rgba(0,0,0,0.2)" } },
            axisLabel: { color: "rgba(0,0,0,0.2)", rotate: 25 },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: "班级得分率", // 不同条柱
            type: "bar",
            label: {
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
              position: "top",
            },
            data: res.data.data.map((item) => item.scoreRate),
            barMaxWidth: 40,
          },
          {
            name: "年级得分率",
            type: "line",
            label: {
              position: "top",
              show: false,
              textStyle: {
                color: "#FF5233",
              },
            },
            data: res.data.data.map((item) => item.schoolScoreRate),
            color: "#FF5233",
          },
        ],
      };
      this.tableData = res.data.data.slice(0, 12);
      this.isExtend = false;
      this.old = res.data.data;
      if (this.$refs.elTable) {
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      }
    },
    async getbzrreportpaperanalysisResponsefeedback() {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetbzrreportpaperanalysisResponsefeedback({
          classNum: this.classNum,
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
          subjectId: this.subjectId,
        });
      } else {
        res = await getbzrreportpaperanalysisResponsefeedback({
          classNum: this.classNum,
          id: this.statId,
          subjectId: this.subjectId,
        });
      }

      this.loading = false;
      this.headerTableData = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.subject {
  .title {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    .title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .tag {
    height: 60px;
    background-color: #f2f8fe;
    line-height: 60px;
    box-sizing: border-box;
    padding: 0 12px;
    margin: 24px 0;
    color: #737677;
    font-size: 14px;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}
::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  .label {
    width: 50px;
    text-align: left;
  }
  .out {
    width: 80px;
    background-color: #eee;
    height: 8px;
    .progress {
      height: 8px;
      background-color: #63a8ec;
    }
  }
  .none {
    background-color: #eee;
    height: 8px;
    flex: 1;
  }
  .total {
    width: 45px;
    text-align: left;
    margin-left: 3px;
  }
}
.el-progress {
  width: 60%;
}
::v-deep .el-progress-bar__outer {
  border-radius: 0;
}
::v-deep .el-progress-bar__inner {
  border-radius: 0;
}
.namList {
  cursor: pointer;
  span {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.green {
  color: #67c23a;
  text-decoration: underline;
}
.red {
  color: #f56c6c;
  text-decoration: underline;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
.btns {
  text-align: center;
  margin-top: 10px;
}
</style>
