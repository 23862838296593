import request from "@/core/services/axios";
export function getbzrreportclasscompetitiveOnline(params) {
  // 班主任报告-班级竞争力-上线情况
  return request({
    url: `/report/statbanzhurenreport/getbzrreportclasscompetitive/online`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportclasscompetitiveStudentlevel(params) {
  // 班主任报告-班级竞争力-学生等级
  return request({
    url: `/report/statbanzhurenreport/getbzrreportclasscompetitive/ratio`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportclasscompetitiveTop(params) {
  // 班主任报告-班级竞争力-前N名
  return request({
    url: `/report/statbanzhurenreport/getbzrreportclasscompetitive/topsection`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportclasscompetitiveOther(params) {
  // 班主任报告-班级竞争力-其他指标
  return request({
    url: `/report/statbanzhurenreport/getbzrreportclasscompetitive/other`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportscoredistributionScore(params) {
  // 班主任报告-成绩分布-分数分布
  return request({
    url: `/report/statbanzhurenreport/getbzrreportscoredistribution/score`,
    method: `post`,
    data: params,
  });
}
export function getbzrreportscoredistributionRank(params) {
  // 班主任报告-成绩分布-排名分布
  return request({
    url: `/report/statbanzhurenreport/getbzrreportscoredistribution/rank`,
    method: `post`,
    data: params,
  });
}
export function getstatinfo(params) {
  // 获取统计和科目信息
  return request({
    url: `/report/stat/getstatinfo`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportsubjectAveragescore(params) {
  // 班主任报告-优劣势学科-平均分
  return request({
    url: `/report/statbanzhurenreport/getbzrreportsubject/averagescore`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportsubjectOnline(params) {
  // 班主任报告-优劣势学科-上线情况
  return request({
    url: `/report/statbanzhurenreport/getbzrreportsubject/online`,
    method: `get`,
    params: params,
  });
}
export function getstatonlineoption(params) {
  // 获取统计的上线下拉列表
  return request({
    url: `/report/stat/getstatonlineoption`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportsubjectRatio(params) {
  // 班主任报告-优劣势学科-比率
  return request({
    url: `/report/statbanzhurenreport/getbzrreportsubject/ratio`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportsubjectTop(params) {
  // 班主任报告-优劣势学科-前N名
  return request({
    url: `/report/statbanzhurenreport/getbzrreportsubject/topsection`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportsubjectOther(params) {
  // 班主任报告-优劣势学科-其他指标
  return request({
    url: `/report/statbanzhurenreport/getbzrreportsubject/other`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportimportstudentTracking(params) {
  // 校级报告-重点关注学生-跟踪生
  return request({
    url: `/report/statbanzhurenreport/getbzrreportimportstudent/track`,
    method: `get`,
    params: params,
  });
}
export function getstatcomparelist(params) {
  // 获取对比考试列表
  return request({
    url: `/report/stat/getstatcomparelist`,
    method: `post`,
    data: params,
  });
}
export function getbzrreportimportstudentWave(params) {
  // 班主任报告-重点关注学生-波动生
  return request({
    url: `/report/statbanzhurenreport/getbzrreportimportstudent/wave`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportimportstudentCritical(params) {
  // 班主任报告-重点关注学生-临界生
  return request({
    url: `/report/statbanzhurenreport/getbzrreportimportstudent/critical`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportimportstudent(params) {
  // 班主任报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statbanzhurenreport/getbzrreportimportstudent/toporbackward`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportdevelopanalysisOveraveragerate(params) {
  // 班主任报告-发展性分析-超均率
  return request({
    url: `/report/statbanzhurenreport/getbzrreportdevelopanalysis/overaveragerate`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportdevelopanalysisOnline(params) {
  // 班主任报告-优劣势学科-上线情况
  return request({
    url: `/report/statbanzhurenreport/getbzrreportdevelopanalysis/online`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportdevelopanalysisRatio(params) {
  // 班主任报告-发展性分析-比率
  return request({
    url: `/report/statbanzhurenreport/getbzrreportdevelopanalysis/ratio`,
    method: `get`,
    params: params,
  });
}
export function getstatratiooption(params) {
  // 获取统计的比率下拉列表
  return request({
    url: `/report/stat/getstatratiooption`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportdevelopanalysisTop(params) {
  // 班主任报告-发展性分析-前N名
  return request({
    url: `/report/statbanzhurenreport/getbzrreportdevelopanalysis/topsection`,
    method: `get`,
    params: params,
  });
}
export function getstattopsectionoption(params) {
  // 获取统计的前N名下拉列表
  return request({
    url: `/report/stat/getstattopsectionoption`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportdevelopanalysisOther(params) {
  // 班主任报告-发展性分析-其他指标
  return request({
    url: `/report/statbanzhurenreport/getbzrreportdevelopanalysis/other`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportpaperanalysisKnowledgepoints(params) {
  // 班主任报告-试卷分析-知识点
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestiondiagknowledgescorerate`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportpaperanalysisInvestigationability(params) {
  // 班主任报告-试卷分析-考察能力
  return request({
    url: `/report/statbanzhurenreport/getbzrreportpaperanalysis/investigationability`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportpaperanalysisQuestionorder(params) {
  // 班主任报告-试卷分析-题目
  return request({
    url: `/report/statbanzhurenreport/getbzrreportpaperanaly/question`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportpaperanalyKnowledge(params) {
  // 班主任报告-试卷分析-应答情况分析-知识点
  return request({
    url: `/report/statbanzhurenreport/getbzrreportpaperanaly/knowledge`,
    method: `get`,
    params: params,
  });
}

export function getbzrreportpaperanalysisResponsefeedback(params) {
  // 班主任报告-试卷分析-学生应答反馈
  return request({
    url: `/report/statbanzhurenreport/getbzrreportpaperanaly/student`,
    method: `get`,
    params: params,
  });
}
export function getbanzhurenreporttopinfo(params) {
  // 获取班主任报告顶部数据
  return request({
    url: `/report/statbanzhurenreport/getbanzhurenreporttopinfo`,
    method: `get`,
    params: params,
  });
}
export function downloadbanzhurenpdfreport(params) {
  // 下载班主任报告PDF文档
  return request({
    url: `/report/statbanzhurenreport/downloadbanzhurenpdfreport`,
    method: `get`,
    params: params,
  });
}
export function getbzrreportdevelopanalysisV2(params) {
  // 班主任报告-发展性分析V2
  return request({
    url: `/report/statbanzhurenreport/getbzrreportdevelopanalysis/v2`,
    method: `get`,
    params: params,
  });
}
export function exportHeadReport(params) {
  // 导出班主任报表
  return request({
    url: `/report/statbanzhurenreport/export`,
    method: `post`,
    data: params,
  });
}
export function exportHeadReportNew(params) {
  // 导出班主任报表-新高考
  return request({
    url: `/report/statnewgaokaobanzhurenreport/export`,
    method: `post`,
    data: params,
  });
}
